<template>
  <IntegrationContent>
    <template #title>
      {{ $t('Web.Transactional.TabSettings') }}
    </template>
    <div
      v-if="isIntegrationEditing"
      class="integration-content-row integration-content-row--column integration-content-row--divider"
    >
      <SlInfoItem
        :label="$t('OrderListDialog.Ui.lbComposeDate')"
        :value="composeDateLabel"
      />
    </div>
    <div class="integration-content-row integration-content-row--column">
      <template v-if="isIntegrationEditing">
        <div
          v-if="isCsv"
          class="integration-content-row"
        >
          <SlSelect
            v-model="csvDelimiterModel"
            :select-label="$t('OrderListDialog.Ui.lbCsvDelimiter')"
            :options="csvDelimiterOptions"
            inline
          />
        </div>
        <div class="integration-content-row">
          <SlValidate
            v-slot="{ invalid }"
            key="headerRowsCount"
            vid="headerRowsCount"
            rules="required|between:0,99"
          >
            <SlControlInput
              v-model="headerRowsCountModel"
              :min="0"
              :max="99"
              :label="$t('OrderListDialog.Ui.lbHeaderRows')"
              :is-invalid="invalid"
            />
          </SlValidate>
        </div>
      </template>
      <div
        v-else
        class="integration-content-row integration-content-row--column"
      >
        <div
          v-if="isCsv"
          class="integration-content-row"
        >
          <SlSelect
            v-model="csvDelimiterModel"
            :select-label="$t('OrderListDialog.Ui.lbCsvDelimiter')"
            :options="csvDelimiterOptions"
            inline
          />
        </div>
        <div class="integration-content-row">
          <SlSelect
            v-model="composeDateModel"
            :select-label="$t('OrderListDialog.Ui.lbComposeDate')"
            :options="composeDateOptions"
            class="w-240"
          />
        </div>
        <div class="integration-content-row">
          <SlValidate
            v-slot="{ invalid }"
            key="headerRowsCount"
            vid="headerRowsCount"
            rules="required|between:0,99"
          >
            <SlControlInput
              v-model="headerRowsCountModel"
              :min="0"
              :max="99"
              :label="$t('OrderListDialog.Ui.lbHeaderRows')"
              :is-invalid="invalid"
            />
          </SlValidate>
        </div>
      </div>
    </div>
  </IntegrationContent>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent';
import { composeDateOptions, csvDelimiterOptions, tabKeys } from '@/config/integrations/spreadsheet.config';

export default {
  name: 'Settings',
  components: {
    IntegrationContent
  },
  data() {
    return {
      csvDelimiterOptions
    };
  },
  inject: ['setSpreadsheetIntegrationSettings'],
  computed: {
    ...mapState({
      tabData: state => state.integrations.spreadsheet[tabKeys.SETTINGS],
      matchedSheets: state => state.integrations.spreadsheet.match_sheets
    }),
    ...mapGetters({
      isCsv: 'integrations/spreadsheet/isCsv',
      isMultipleFiles: 'integrations/spreadsheet/isMultipleFiles',
      isIntegrationEditing: 'integrations/isIntegrationEditing'
    }),
    composeDateOptions() {
      return composeDateOptions(this);
    },
    csvDelimiterModel: {
      get() {
        return this.tabData.csvDelimiter;
      },
      set(value) {
        this.setSpreadsheetIntegrationSettings({ value, key: 'csvDelimiter' });
      }
    },
    composeDateModel: {
      get() {
        return this.tabData.composeDate;
      },
      set(value) {
        this.setSpreadsheetIntegrationSettings({ value, key: 'composeDate' });
      }
    },
    headerRowsCountModel: {
      get() {
        return this.tabData.headerRowsCount;
      },
      set(value) {
        this.setSpreadsheetIntegrationSettings({ value: +value, key: 'headerRowsCount' });
      }
    },
    uniteLocationsModel: {
      get() {
        return this.tabData.uniteLocations;
      },
      set(value) {
        this.setSpreadsheetIntegrationSettings({ value, key: 'uniteLocations' });
      }
    },
    composeDateLabel() {
      return this.composeDateOptions.find(option => option.value === this.composeDateModel).label;
    }
  }
};
</script>

<style lang="scss" scoped>
.connector-content-block::v-deep {
  .connector-content-block__main {
    row-gap: 0;

    height: auto !important;
    max-height: initial !important;
    padding-bottom: 24px;
  }
}
</style>
