<template>
  <SlModal
    :id="id"
    :width="420"
    persistent
    no-header
    @created="onCreated"
    @hide="onModalHide"
  >
    <div class="modal-content oauth-login">
      <SlLoader :text="false" />
      <div class="oauth-login__title heading-4-sb grey-90">
        {{ $t('Web.Modals.OAuthLoginTitle', { 1: source }) }}
      </div>
      <div class="body-1 grey-70">
        {{ $t('Web.Modals.OAuthLoginText') }}
      </div>
    </div>

    <template #footer>
      <SlModalFooter between>
        <SlButton
          variant="secondary"
          color="grey"
          full-width
          @click="handleLoginCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          full-width
          @click="handleLoginTryAgain"
        >
          {{ $t('Web.BC365.CloudTypeAuthTryAgain') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';

export default {
  name: 'OAuthLoginModal',
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      source: null,
      tryAgainCallback: null,
      cancelCallback: null
    };
  },
  methods: {
    onModalHide() {
      this.source = null;
      this.tryAgainCallback = null;
      this.cancelCallback = null;
    },
    handleLoginTryAgain() {
      this.tryAgainCallback && this.tryAgainCallback();
    },
    handleLoginCancel() {
      this.cancelCallback && this.cancelCallback();

      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/oauth-login-modal.scss";
</style>