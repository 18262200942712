<template>
  <SubPageWrapper empty-cols>
    <ContentBlock>
      <IntegrationContent>
        <template #title>
          {{ $t('Web.Integrations.ConnectionTitle', { 1: $t('Web.CIN7.Title')}) }}
        </template>

        <ValidationObserver
          ref="observer"
          slim
        >
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="username"
              rules="required"
            >
              <SlInput
                v-model="apiLogin"
                :label="$t('Web.CIN7.APIUsername')"
                :is-invalid="invalid"
                :disabled="isConnecting"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="key"
              rules="required"
            >
              <SlPasswordInput
                v-model="apiPassword"
                :label="$t('Web.CIN7.APIKey')"
                :is-invalid="invalid"
                :disabled="isConnecting"
                required
              />
            </SlValidate>
          </div>
        </ValidationObserver>

        <template #actions>
          <SlButton
            v-if="!isExport"
            :loading="isConnecting"
            @click="handleConnect"
          >
            {{ $t('Web.DbImport.ButtonConnect') }}
          </SlButton>
        </template>
      </IntegrationContent>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import { modal } from '@/mixins/modal';
import { integrationModuleTypes, stepStatuses } from '@/config/integrations';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'Connect',
  components: {
    IntegrationContent
  },
  mixins: [modal],
  data() {
    return {
      apiLogin: '',
      apiPassword: '',
      isConnecting: false
    };
  },
  inject: ['resetStepsToCurrent'],
  computed: {
    ...mapState({
      connection: state => state.integrations.settings.connection,
      activeIntegration: state => state.integrations.active_integration
    }),
    ...mapGetters({
      activeStep: 'integrations/activeStep',
      isAllStepsCompleted: 'integrations/isAllStepsCompleted'
    }),
    isExport() {
      return this.activeIntegration.module === integrationModuleTypes.EXPORT;
    }
  },
  created() {
    Object.keys(this.connection).forEach(key => {
      if (this[key] !== undefined) {
        this[key] = this.connection[key];
      }
    });
  },
  methods: {
    ...mapActions({
      setActiveStepStatus: 'integrations/setActiveStepStatus',
      updateConnectionSettings: 'integrations/updateConnectionSettings',
      testDatasourceConnection: 'integrations/testDatasourceConnection',
      resetTransformState: 'integrations/transform/resetState',
      updateIntegrationSettings: 'integrations/updateIntegrationSettings'
    }),
    getConnectionSettings() {
      return {
        apiLogin: this.apiLogin,
        apiPassword: this.apiPassword
      };
    },
    async handleConnect() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        return;
      }

      if (this.activeStep.status === stepStatuses.COMPLETED) {
        return this.resetStepsToCurrent(() => {
          this.resetTransformState();
          this.connectToCin7();
        });
      }

      this.connectToCin7();
    },
    async connectToCin7() {
      try {
        this.isConnecting = true;

        await this.updateConnectionSettings(this.getConnectionSettings());
        await this.testDatasourceConnection();

        this.setActiveStepStatus(stepStatuses.COMPLETED);

        this.$notify({
          type: 'success',
          text: this.$t('Web.Integrations.ConnectSuccess', { 1: this.$t('Web.CIN7.Title')})
        });

        return true;
      } catch (e) {
        this.showModal(modalsId.CONNECTOR_ERROR, {
          error: e?.message ?? this.$t('Web.Integrations.ConnectError', { 1: this.$t('Web.CIN7.Title')})
        });
      } finally {
        this.isConnecting = false;
      }
    },
    async finish() {
      try {
        const valid = await this.$refs.observer.validate();

        if (!valid) {
          return;
        }

        const isConnected = await this.connectToCin7();

        await this.updateIntegrationSettings();

        return isConnected && this.isAllStepsCompleted;
      } catch {
        return false;
      }
    }
  }
};
</script>
