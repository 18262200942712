<template>
  <SubPageWrapper empty-cols>
    <ContentBlock>
      <IntegrationContent>
        <template #title>
          {{ $t('Web.Integrations.ConnectionTitle', { 1: $t('Web.DynamicsGP.Title')}) }}
        </template>

        <ValidationObserver
          ref="observer"
          slim
        >
          <div class="integration-content-row integration-content-row--split w-50">
            <SlValidate
              v-slot="{ invalid }"
              key="host"
              vid="host"
              rules="required"
            >
              <SlInput
                v-model="host"
                :label="$t('DbInsideSourceWidget.Ui.lbHost')"
                :disabled="isConnecting"
                :is-invalid="invalid"
                required
              >
                <template #icon>
                  <SlInfoButton
                    v-tooltip="getTooltip($t('Web.DbRelatedConnectors.Form.TooltipGpServiceHost'))"
                  />
                </template>
              </SlInput>
            </SlValidate>
            <SlValidate
              v-slot="{ invalid }"
              key="port"
              vid="port"
              rules="required"
              class="integration-content-row--port"
            >
              <SlInput
                v-model="port"
                :label="$t('Web.DbRelatedConnectors.Form.LabelGpPort')"
                :disabled="isConnecting"
                :is-invalid="invalid"
                type="number"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="username"
              rules="required"
            >
              <SlInput
                v-model="username"
                :label="$t('Web.DbRelatedConnectors.Form.LabelWinUsername')"
                :disabled="isConnecting"
                :is-invalid="invalid"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="password"
              rules="required"
              mode="eager"
            >
              <SlPasswordInput
                v-model="password.value"
                :label="$t('Web.DbRelatedConnectors.Form.LabelWinPassword')"
                :disabled="isConnecting"
                :is-set="password.isSet"
                :is-invalid="invalid"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="companyKey"
              rules="required"
              mode="eager"
            >
              <SlInput
                v-model="companyKey"
                type="number"
                :label="$t('Web.DbRelatedConnectors.Form.LabelCompanyId')"
                :disabled="isConnecting"
                :is-invalid="invalid"
                required
              />
            </SlValidate>
          </div>
        </ValidationObserver>
      </IntegrationContent>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import { modal } from '@/mixins/modal';
import { stepStatuses } from '@/config/integrations';
import modalsId from '@/config/shared/modalsId.config';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'SetUp',
  components: {
    IntegrationContent
  },
  mixins: [modal],
  data() {
    return {
      getTooltip,
      host: '',
      port: '',
      username: '',
      password: {
        value: '',
        isSet: false
      },
      companyKey: null,
      isConnecting: false
    };
  },
  inject: ['resetStepsToCurrent'],
  computed: {
    ...mapState({
      configuration: state => state.integrations.settings.configuration
    }),
    ...mapGetters({
      activeStep: 'integrations/activeStep',
      isAllStepsCompleted: 'integrations/isAllStepsCompleted'
    })
  },
  created() {
    Object.keys(this.configuration).forEach(key => {
      if (this[key] !== undefined) {
        this[key] = this.configuration[key];
      }
    });
  },
  methods: {
    ...mapActions({
      setActiveStepStatus: 'integrations/setActiveStepStatus',
      testDatasourceConnection: 'integrations/testDatasourceConnection',
      updateIntegrationSettings: 'integrations/updateIntegrationSettings'
    }),
    getConnectionSettings() {
      return {
        host: this.host,
        port: this.port,
        username: this.username,
        password: this.password,
        companyKey: this.companyKey
      };
    },
    async connectToDatasource() {
      try {
        this.isConnecting = true;

        await this.updateIntegrationSettings(this.getConnectionSettings());

        const isConnected = await this.testDatasourceConnection();

        if (!isConnected) {
          return this.showModal(modalsId.CONNECTOR_ERROR, {
            error: this.$t('Web.Integrations.ConnectError', { 1: this.$t('Web.DynamicsGP.Title')})
          });
        }

        this.setActiveStepStatus(stepStatuses.COMPLETED);

        this.$notify({
          type: 'success',
          text: this.$t('Web.Integrations.ConnectSuccess', { 1: this.$t('Web.DynamicsGP.Title')})
        });

        return true;
      } catch (e) {
        this.showModal(modalsId.CONNECTOR_ERROR, {
          error: e?.message ?? this.$t('Web.Integrations.ConnectError', { 1: this.$t('Web.DynamicsGP.Title')})
        });
      } finally {
        this.isConnecting = false;
      }
    },
    async finish() {
      try {
        const valid = await this.$refs.observer.validate();

        if (!valid) {
          return;
        }

        const isConnected = await this.connectToDatasource();

        return isConnected && this.isAllStepsCompleted;
      } catch {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.integration-content {
  &__main {
    .integration-content-row {
      &.integration-content-row--split {
        align-items: flex-start;

        .integration-content-row--port {
          max-width: 160px;

          .sl-input-wrapper {
            min-width: 160px;
          }
        }
      }
    }
  }
}
</style>
