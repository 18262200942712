<template>
  <div class="integration">
    <SlOverlay :show="isLoading" />
    <DatasourceConnectionError :id="modalsId.CONNECTOR_ERROR" />

    <IntegrationToolbar
      @step-change="handleStepChanged"
      @finish="handleFinish"
    />
    <div class="integration__content">
      <transition name="fade-tab">
        <component
          :is="component"
          v-if="component && integrationInitialized"
          ref="step"
          @on-validation="handleValidation"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import IntegrationToolbar from '@/components/Integrations/IntegrationToolbar.vue';
import DatasourceConnectionError from '@/components/Modals/Connections/Connectors/Shared/DatasourceConnectionError';
import SpreadsheetUploadFile from '@/components/Integrations/Modules/Spreadsheet/UploadFile.vue';
import SpreadsheetMatchSheets from '@/components/Integrations/Modules/Spreadsheet/MatchSheets.vue';
import SpreadsheetPullData from '@/components/Integrations/Modules/Spreadsheet/PullData.vue';
import ExportTransform from '@/components/Integrations/Modules/Transform/Export/Index.vue';
import ImportTransform from '@/components/Integrations/Modules/Transform/Import/Index.vue';
import QbConnect from '@/components/Integrations/Modules/Qb/Connect.vue';
import QbPullData from '@/components/Integrations/Modules/Qb/PullData.vue';
import AmazonConnect from '@/components/Integrations/Modules/Amazon/Connect.vue';
import AmazonPullData from '@/components/Integrations/Modules/Amazon/PullData.vue';
import BC365Connect from '@/components/Integrations/Modules/BC365/Connect.vue';
import BC365PullData from '@/components/Integrations/Modules/BC365/PullData.vue';
import CustomApiPullData from '@/components/Integrations/Modules/CustomApi/PullData.vue';
import CustomApiSetUp from '@/components/Integrations/Modules/CustomApi/SetUp.vue';
import DatabaseConnect from '@/components/Integrations/Modules/Database/Connect.vue';
import SapConnect from '@/components/Integrations/Modules/Sap/Connect.vue';
import SapSetUp from '@/components/Integrations/Modules/Sap/SetUp.vue';
import Cin7Connect from '@/components/Integrations/Modules/Cin7/Connect.vue';
import Cin7PullData from '@/components/Integrations/Modules/Cin7/PullData.vue';
import Cin7CoreConnect from '@/components/Integrations/Modules/Cin7Core/Connect.vue';
import Cin7CorePullData from '@/components/Integrations/Modules/Cin7Core/PullData.vue';
import NetsuiteConnect from '@/components/Integrations/Modules/Netsuite/Connect.vue';
import NetsuiteSetUp from '@/components/Integrations/Modules/Netsuite/SetUp.vue';
import DynamicsNavConnect from '@/components/Integrations/Modules/DynamicsNav/Connect.vue';
import DynamicsNavSetUp from '@/components/Integrations/Modules/DynamicsNav/SetUp.vue';
import DynamicsGpConnect from '@/components/Integrations/Modules/DynamicsGp/Connect.vue';
import DynamicsGpSetUp from '@/components/Integrations/Modules/DynamicsGp/SetUp.vue';
import FishbowlConnect from '@/components/Integrations/Modules/Fishbowl/Connect.vue';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { routeNames } from '@/config/router/router.config';

export default {
  name: 'Integration',
  components: {
    IntegrationToolbar,
    DatasourceConnectionError,
    SpreadsheetUploadFile,
    SpreadsheetMatchSheets,
    SpreadsheetPullData,
    ImportTransform,
    ExportTransform,
    QbConnect,
    QbPullData,
    AmazonConnect,
    AmazonPullData,
    BC365Connect,
    BC365PullData,
    CustomApiPullData,
    CustomApiSetUp,
    DatabaseConnect,
    SapConnect,
    SapSetUp,
    Cin7Connect,
    Cin7PullData,
    Cin7CoreConnect,
    Cin7CorePullData,
    NetsuiteConnect,
    NetsuiteSetUp,
    DynamicsNavConnect,
    DynamicsNavSetUp,
    DynamicsGpConnect,
    DynamicsGpSetUp,
    FishbowlConnect
  },
  mixins: [modal],
  data() {
    return {
      modalsId,
      isLoading: false
    };
  },
  provide() {
    return {
      resetStepsToCurrent: this.resetStepsToCurrent,
      setIsLoading: this.setIsLoading
    };
  },
  computed: {
    ...mapState({
      steps: state => state.integrations.active_steps || [],
      integrationInitialized: state => state.integrations.integration_initialized,
      isEdited: state => state.integrations.is_edited,
      activeIntegration: state => state.integrations.active_integration
    }),
    ...mapGetters({
      activeStepIndex: 'integrations/activeStepIndex'
    }),
    component() {
      return this.steps[this.activeStepIndex]?.component;
    }
  },
  async beforeMount() {
    if (this.integrationInitialized) {
      return;
    }

    await this.fetchIntegrationById({
      id: this.$route.params.integrationId,
      ...this.activeIntegration
    });
  },
  beforeRouteLeave(_, __, next) {
    if (this.isEdited) {
      return this.showModal(modalsId.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Integrations.Modals.TitleLeave'),
        text: this.$t('Web.Integrations.Modals.TextLeave'),
        icon: 'style_warning_double',
        confirmText: this.$t('Web.Integrations.Modals.BtnLeave'),
        confirmCallback: () => {
          this.resetIntegrationState();

          next();
        }
      });
    }

    this.resetIntegrationState();

    next();
  },
  methods: {
    ...mapActions({
      setActiveIntegration: 'integrations/setActiveIntegration',
      setActiveSteps: 'integrations/setActiveSteps',
      switchActiveStep: 'integrations/switchActiveStep',
      resetStatusToStep: 'integrations/resetStatusToStep',
      resetIntegrationState: 'integrations/resetIntegrationState',
      fetchIntegrationById: 'integrations/fetchIntegrationById',
      setIsEdited: 'integrations/setIsEdited',
      logout: 'user/logout'
    }),
    handleValidation(value) {
      if (!value) {
        this.$notify({
          type: 'error',
          text: this.$t('Web.Integrations.Errors.PullData')
        });
      }
    },
    setIsLoading(value) {
      this.isLoading = value;
    },
    resetStepsToCurrent(cb) {
      return this.showModal(modalsId.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Integrations.Modals.TitleResetToStep'),
        text: this.$t('Web.Integrations.Modals.TextResetToStep'),
        confirmText: this.$t('Web.Modals.BtnConfirm'),
        icon: 'style_warning_double',
        confirmCallback: () => {
          this.resetStatusToStep(this.activeStepIndex);

          cb();
        }
      });
    },
    async handleStepChanged(index) {
      this.$refs.step?.submit && await this.$refs.step.submit();

      this.switchActiveStep(index);
    },
    async handleFinish() {
      if (!this.$refs.step?.finish) {
        return;
      }

      try {
        const success = await this.$refs.step.finish();

        if (!success) {
          return;
        }

        await this.setIsEdited(false);

        this.$router.push({
          name: routeNames.INTEGRATIONS
        });
      } catch (e) {
        this.logout({
          e,
          from: 'integration/handleFinish'
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.integration {
  width: 100%;
  height: 100%;

  &__content {
    position: relative;

    width: 100%;
    height: 100%;

    &::v-deep {
      .subpage-wrapper {
        overflow: hidden;
      }

      .content-block__main {
        height: calc(100% - 50px);
      }
    }
  }
}
</style>
