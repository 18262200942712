<template>
  <SubPageWrapper empty-cols>
    <ContentBlock>
      <IntegrationContent>
        <template #title>
          <div class="title-wrapper">
            {{ $t('Web.Integrations.ConnectionTitle', { 1: $t('Web.DbImport.Title')}) }}
            <SlLink
              :href="GUIDE_DATABASE_CONNECTION_URL"
              target="_blank"
            >
              <template #prepend>
                <icon
                  data="@icons/question.svg"
                  class="fill-off size-16"
                />
              </template>
              {{ $t('Web.DbImport.LinkHelp') }}
            </SlLink>
          </div>
        </template>

        <ValidationObserver
          ref="observer"
          slim
        >
          <div class="integration-content-row w-50">
            <SlControl
              v-model="driverType"
              :options="connectionTypeOptions"
              :label="$t('Web.DbImport.LabelConnectionType')"
              :disabled="isConnecting"
            />
          </div>
          <template v-if="isSQLConnection">
            <div class="integration-content-row integration-content-row--split w-50">
              <SlValidate
                v-slot="{ invalid }"
                key="host"
                vid="host"
                rules="required"
              >
                <SlInput
                  v-model="server"
                  :label="$t('DbInsideSourceWidget.Ui.lbHost')"
                  :disabled="isConnecting"
                  :is-invalid="invalid"
                  required
                />
              </SlValidate>
              <SlValidate
                v-slot="{ invalid }"
                key="port"
                vid="port"
                rules="required"
                class="integration-content-row--port"
              >
                <SlInput
                  v-model="port"
                  :label="$t('DbInsideSourceWidget.Ui.lbPort')"
                  :disabled="isConnecting"
                  :is-invalid="invalid"
                  type="number"
                  required
                />
              </SlValidate>
            </div>
            <div class="integration-content-row w-50">
              <SlValidate
                v-slot="{ invalid }"
                key="databaseName"
                vid="databaseName"
                rules="required"
              >
                <SlInput
                  v-model="database"
                  :label="$t('DbInsideSourceWidget.Ui.lbDatabase')"
                  :disabled="isConnecting"
                  :is-invalid="invalid"
                  required
                />
              </SlValidate>
            </div>
          </template>
          <template v-else>
            <div class="integration-content-row">
              <SlValidate
                v-slot="{ invalid }"
                key="datasourceName"
                vid="datasourceName"
                rules="required"
              >
                <SlInput
                  v-model="datasourceName"
                  :label="$t('Web.DbImport.ConnectionString')"
                  :placeholder="datasourceNamePlaceholder"
                  :disabled="isConnecting"
                  :is-invalid="invalid"
                  required
                />
              </SlValidate>
            </div>
          </template>
          <div class="integration-content-row w-50">
            <SlInput
              v-model="username"
              :label="$t('DbImportDialog.Ui.lbUserName')"
              :disabled="isConnecting"
            />
          </div>
          <div class="integration-content-row w-50 mb-0">
            <SlPasswordInput
              v-model="password.value"
              :label="$t('DbImportDialog.Ui.lbPassword')"
              :is-set="password.isSet"
              :disabled="isConnecting"
            />
          </div>
        </ValidationObserver>

        <template #actions>
          <SlButton
            :loading="isConnecting"
            @click="handleConnect"
          >
            {{ $t('Web.DbImport.ButtonConnect') }}
          </SlButton>
        </template>
      </IntegrationContent>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import { modal } from '@/mixins/modal';
import { stepStatuses } from '@/config/integrations';
import { dbConnectionTypes } from '@/config/connection/database.config';
import { databaseConnectionTypes, connectionTypeOptions } from '@/config/integrations/databaseRelated.config';
import { GUIDE_DATABASE_CONNECTION_URL } from '@/config/shared/resources.config';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'Connect',
  components: {
    IntegrationContent
  },
  mixins: [modal],
  data() {
    return {
      GUIDE_DATABASE_CONNECTION_URL,
      connectionTypeOptions,
      driverType: databaseConnectionTypes.ODBC,
      datasourceName: '',
      database: '',
      port: '',
      server: '',
      username: '',
      password: {
        value: '',
        isSet: false
      },
      isConnecting: false
    };
  },
  inject: [
    'resetStepsToCurrent'
  ],
  computed: {
    ...mapState({
      connection: state => state.integrations.settings.connection
    }),
    ...mapGetters({
      activeStep: 'integrations/activeStep'
    }),
    datasourceNamePlaceholder() {
      return this.driverType === databaseConnectionTypes.BIG_QUERY
        ? ''
        : this.$t('Web.DbImport.PlaceholderDatasourceName');
    },
    isSQLConnection() {
      return this.driverType === dbConnectionTypes.MYSQL;
    }
  },
  created() {
    Object.keys(this.connection).forEach(key => {
      if (this[key] !== undefined) {
        this[key] = this.connection[key];
      }
    });
  },
  methods: {
    ...mapActions({
      setActiveStepStatus: 'integrations/setActiveStepStatus',
      updateConnectionSettings: 'integrations/updateConnectionSettings',
      testDatasourceConnection: 'integrations/testDatasourceConnection',
      resetTransformState: 'integrations/transform/resetState'
    }),
    getConnectionSettings() {
      return {
        driverType: this.driverType,
        datasourceName: this.datasourceName,
        database: this.database,
        port: this.port,
        server: this.server,
        username: this.username,
        password: this.password
      };
    },
    async handleConnect() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        return;
      }

      if (this.activeStep.status === stepStatuses.COMPLETED) {
        return this.resetStepsToCurrent(() => {
          this.resetTransformState();
          this.connectToDatasource();
        });
      }

      this.connectToDatasource();
    },
    async connectToDatasource() {
      try {
        this.isConnecting = true;

        await this.updateConnectionSettings(this.getConnectionSettings());
        await this.testDatasourceConnection();

        this.setActiveStepStatus(stepStatuses.COMPLETED);

        this.$notify({
          type: 'success',
          text: this.$t('Web.Integrations.ConnectSuccess', { 1: this.$t('Web.DbImport.Title')})
        });
      } catch (e) {
        this.showModal(modalsId.CONNECTOR_ERROR, {
          error: e?.message ?? this.$t('Web.Integrations.ConnectSuccess', { 1: this.$t('Web.DbImport.Title')})
        });
      } finally {
        this.isConnecting = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.integration-content {
  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__main {
    .integration-content-row {
      &.integration-content-row--split {
        align-items: flex-start;

        .integration-content-row--port {
          max-width: 120px;

          .sl-input-wrapper {
            min-width: 120px;
          }
        }
      }
    }
  }
}
</style>
