var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"toolbar"},[_c('div',{staticClass:"toolbar__container"},[_c('div',{staticClass:"toolbar__col toolbar__col--title"},[_c('SlButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip(_vm.$t('Web.Integrations.Tooltips.BackToIntegrations'))),expression:"getTooltip($t('Web.Integrations.Tooltips.BackToIntegrations'))"}],attrs:{"variant":"tertiary","color":"grey","size":"xs","icon":""},on:{"click":_vm.handleBackToIntegrations}},[_c('icon',{staticClass:"fill-off size-20",attrs:{"data":require("@icons/close.svg")}})],1),_c('div',{staticClass:"toolbar__integration-name heading-5 grey-80",attrs:{"title":_vm.activeIntegration.name}},[_vm._v(" "+_vm._s(_vm.activeIntegration.name)+" ")])],1),_c('div',{staticClass:"toolbar__inner-container"},[_c('div',{staticClass:"toolbar__col"},[_c('div',{staticClass:"toolbar__col toolbar__col--nav"},[(!_vm.isFirstStep)?_c('SlButton',{attrs:{"variant":"secondary","color":"grey","disabled":!_vm.previousStep},on:{"click":function($event){return _vm.handleChangeStep(_vm.previousStep, 'prev')}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-16 color-grey-80",attrs:{"data":require("@icons/chevron_left.svg")}})]},proxy:true}],null,false,3153767201)},[_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.Actions.BtnBack'))+" ")]):_vm._e()],1),_c('div',{staticClass:"toolbar__col toolbar__col--steps"},_vm._l((_vm.steps),function(step,index){return _c('IntegrationStep',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip({
              content: step.tooltip,
              disabled: _vm.isAvailable(index)
            })),expression:"getTooltip({\n              content: step.tooltip,\n              disabled: isAvailable(index)\n            })"}],key:step.type,attrs:{"active":step.active,"completed":_vm.isCompleted(index),"disabled":_vm.isDisabled(index),"available":_vm.isAvailable(index),"divider":index < _vm.steps.length - 1},on:{"click":function($event){return _vm.handleChangeStep(step)}}},[_vm._v(" "+_vm._s(_vm.$t(("Web.Integrations.Steps." + (step.type))))+" ")])}),1),_c('div',{staticClass:"toolbar__col toolbar__col--nav toolbar__col--nav-end"},[(!_vm.isLastStep)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip({
              content: _vm.$t('Web.Integrations.Tooltips.CompleteCurrentStep'),
              disabled: !_vm.isNextDisabled,
              style: 'width: 250px;'
            })),expression:"getTooltip({\n              content: $t('Web.Integrations.Tooltips.CompleteCurrentStep'),\n              disabled: !isNextDisabled,\n              style: 'width: 250px;'\n            })"}]},[_c('SlButton',{attrs:{"disabled":_vm.isNextDisabled},on:{"click":function($event){return _vm.handleChangeStep(_vm.nextStep, 'next')}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('icon',{staticClass:"fill-off size-16 color-white-stroke",attrs:{"data":require("@icons/chevron_right.svg")}})]},proxy:true}],null,false,1071003390)},[_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.Actions.BtnNext'))+" ")])],1):_vm._e(),(_vm.isLastStep)?_c('SlButton',{on:{"click":_vm.handleFinish}},[_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.Actions.BtnFinish'))+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"toolbar__col"})])])}
var staticRenderFns = []

export { render, staticRenderFns }