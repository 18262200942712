<template>
  <SubPageWrapper empty-cols>
    <ContentBlock>
      <IntegrationContent>
        <template #title>
          {{ $t('Web.Integrations.Actions.BtnPullData') }}
        </template>
        <div
          v-if="isDataPulled"
          class="integration-content-row"
        >
          <SlAlert
            :accent="$t('Web.Integrations.Alerts.PullSuccess')"
            type="success"
          />
        </div>
        <ValidationObserver ref="observer">
          <div class="integration-content-row mb-20">
            <SlValidate
              v-slot="{ invalid }"
              vid="startDate"
              :rules="startDateRules"
            >
              <SlDatePicker
                v-model="startDate"
                :label="$t('DbInsideImport.Ui.lbStartDate')"
                :is-invalid="invalid"
                class="w-160"
                inline
              />
            </SlValidate>
          </div>
          <div class="integration-content-row mb-20 w-480">
            <SlValidate
              v-slot="{ invalid }"
              vid="marketplaces"
              rules="required"
            >
              <SlSelect
                v-model="marketplaces"
                :options="marketplaceOptions"
                :select-label="$t('Web.Amazon.Marketplaces')"
                :is-invalid="invalid"
                :placeholder="$t('Web.Select.SelectOrTypeToSearch')"
                :close-on-select="false"
                required
                multiple
                searchable
                allow-empty
                clearable
              />
            </SlValidate>
          </div>
          <div class="integration-content-row mb-0">
            <SlControl
              v-model="distributorView"
              :label="$t('Web.Amazon.DistributorView')"
              :options="distributorViewOptions"
            />
          </div>
        </ValidationObserver>
        <template #actions>
          <SlButton
            v-if="!isDataPulled"
            @click="handlePullData"
          >
            {{ $t('Web.Integrations.Actions.BtnPullData') }}
          </SlButton>
          <SlButton
            v-else
            variant="secondary"
            color="grey"
            @click="handleRePull"
          >
            {{ $t('Web.Integrations.Actions.BtnRePull') }}
          </SlButton>
        </template>
      </IntegrationContent>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import logger from '@/api/logger';
import moment from 'moment';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { stepStatuses } from '@/config/integrations';
import { valueFormat } from '@/config/shared/datePicker.config';

export default {
  name: 'PullData',
  components: {
    IntegrationContent
  },
  mixins: [modal],
  inject: ['resetStepsToCurrent'],
  data() {
    return {
      distributorViewOptions: [
        {
          label: this.$t('Web.Amazon.Sourcing'),
          value: 'Sourcing'
        },
        {
          label: this.$t('Web.Amazon.Manufacturing'),
          value: 'Manufacturing'
        }
      ]
    };
  },
  computed: {
    ...mapState({
      configuration: state => state.integrations.settings.configuration,
      marketplaceOptions: state => state.integrations.amazon.marketplaces
    }),
    ...mapGetters({
      activeStep: 'integrations/activeStep'
    }),
    isDataPulled() {
      return this.activeStep.status === stepStatuses.COMPLETED;
    },
    startDate: {
      get() {
        return this.configuration.startDate;
      },
      set(value) {
        this.updateConfiguration({
          startDate: value
        });
      }
    },
    marketplaces: {
      get() {
        return this.configuration.marketplaces;
      },
      set(value) {
        this.updateConfiguration({
          marketplaces: value
        });
      }
    },
    distributorView: {
      get() {
        return this.configuration.distributorView;
      },
      set(value) {
        this.updateConfiguration({
          distributorView: value
        });
      }
    },
    minStartDate() {
      return moment().subtract(3, 'years').format(valueFormat.date);
    },
    startDateRules() {
      return {
        required: true,
        sl_valid_date: true,
        sl_date_between: {
          startDate: this.minStartDate,
          endDate: moment().format(valueFormat.date),
          offset: 0
        }
      };
    }
  },
  async beforeMount() {
    await this.fetchMarketplaces();

    if (moment(this.startDate).isBefore(moment(this.minStartDate))) {
      this.startDate = this.minStartDate;
    }
  },
  methods: {
    ...mapActions({
      updateConfiguration: 'integrations/settings/updateConfiguration',
      setActiveStepStatus: 'integrations/setActiveStepStatus',
      fetchMarketplaces: 'integrations/amazon/fetchMarketplaces',
      updateIntegrationSettings: 'integrations/updateIntegrationSettings',
      importIntegration: 'integrations/importIntegration',
      resetTransformState: 'integrations/transform/resetState',
      subscribe: 'operations/subscribe'
    }),
    handleRePull() {
      this.resetStepsToCurrent(() => {
        this.resetTransformState();
        this.handlePullData();
      });
    },
    async handlePullData() {
      try {
        const valid = await this.$refs.observer.validate();

        if (!valid) {
          return;
        }

        this.showModal(modalsId.SL_LOADING_MODAL, {
          title: this.$t('Web.Integrations.Modals.TitlePullingData'),
          text: this.$t('Web.Integrations.Modals.TextPullingData')
        });

        await this.updateIntegrationSettings();

        await this.subscribe({
          subscriber: this.importIntegration
        });

        this.setActiveStepStatus(stepStatuses.COMPLETED);
      } catch (e) {
        logger.formatAndWriteError({
          e,
          from: 'amazon/handlePullData'
        });

        const message = e?.message ?? this.$t('Web.Integrations.Modals.TextPullingDataError');

        this.showModal(modalsId.SL_ERROR_MODAL, {
          title: this.$t('Web.Integrations.Modals.TitlePullingDataError'),
          text: message
        });
      } finally {
        setTimeout(() => {
          this.hideModal(modalsId.SL_LOADING_MODAL);
        }, 150);
      }
    }
  }
};
</script>
