<template>
  <SubPageWrapper empty-cols>
    <ContentBlock>
      <IntegrationContent>
        <template #title>
          {{ $t('Web.Integrations.ConnectionTitle', { 1: $t('Web.Amazon.Title')}) }}
        </template>

        <ValidationObserver
          ref="observer"
          slim
        >
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="clientId"
              rules="required"
            >
              <SlInput
                v-model="clientId"
                :label="$t('Web.Amazon.ClientId')"
                :is-invalid="invalid"
                :disabled="isConnecting"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="clientSecret"
              rules="required"
            >
              <SlPasswordInput
                v-model="clientSecret"
                :label="$t('Web.Amazon.ClientSecret')"
                :is-invalid="invalid"
                :disabled="isConnecting"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="refreshToken"
              rules="required"
            >
              <SlPasswordInput
                v-model="refreshToken"
                :label="$t('Web.Amazon.RefreshToken')"
                :is-invalid="invalid"
                :disabled="isConnecting"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="awsRegion"
              rules="required"
            >
              <SlSelect
                v-model="awsRegion"
                :options="awsRegionOptions"
                :select-label="$t('Web.Amazon.AWSRegion')"
                :is-invalid="invalid"
                :disabled="isConnecting"
                required
              >
                <template #selectOption="{ option, selected }">
                  <div class="aws-region-option">
                    <div
                      class="aws-region-option__label"
                      :class="{
                        'aws-region-option__label--selected': selected
                      }"
                    >
                      {{ option.label }}
                      <icon
                        v-if="selected"
                        data="@icons/check.svg"
                        class="fill-off size-16 color-primary-80 check-icon"
                      />
                    </div>
                    <div class="aws-region-option__description">
                      {{ option.description }}
                    </div>
                  </div>
                </template>
              </SlSelect>
            </SlValidate>
          </div>
        </ValidationObserver>

        <template #actions>
          <SlButton
            :loading="isConnecting"
            @click="handleConnect"
          >
            {{ $t('Web.DbImport.ButtonConnect') }}
          </SlButton>
        </template>
      </IntegrationContent>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import { modal } from '@/mixins/modal';
import { stepStatuses } from '@/config/integrations';
import { awsRegionOptions } from '@/config/integrations/amazon.config';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'Connect',
  components: {
    IntegrationContent
  },
  mixins: [modal],
  data() {
    return {
      awsRegionOptions: awsRegionOptions(this),
      clientId: '',
      clientSecret: '',
      refreshToken: '',
      awsRegion: '',
      isConnecting: false
    };
  },
  inject: [
    'resetStepsToCurrent'
  ],
  computed: {
    ...mapState({
      connection: state => state.integrations.settings.connection,
      activeIntegration: state => state.integrations.active_integration
    }),
    ...mapGetters({
      activeStep: 'integrations/activeStep'
    })
  },
  created() {
    Object.keys(this.connection).forEach(key => {
      if (this[key] !== undefined) {
        this[key] = this.connection[key];
      }
    });
  },
  methods: {
    ...mapActions({
      setActiveStepStatus: 'integrations/setActiveStepStatus',
      updateConnectionSettings: 'integrations/updateConnectionSettings',
      testDatasourceConnection: 'integrations/testDatasourceConnection',
      resetTransformState: 'integrations/transform/resetState'
    }),
    getConnectionSettings() {
      return {
        clientId: this.clientId,
        clientSecret: this.clientSecret,
        refreshToken: this.refreshToken,
        awsRegion: this.awsRegion
      };
    },
    async handleConnect() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        return;
      }

      if (this.activeStep.status === stepStatuses.COMPLETED) {
        return this.resetStepsToCurrent(() => {
          this.resetTransformState();
          this.connectToAmazon();
        });
      }

      this.connectToAmazon();
    },
    async connectToAmazon() {
      try {
        this.isConnecting = true;

        await this.updateConnectionSettings(this.getConnectionSettings());
        await this.testDatasourceConnection();

        this.setActiveStepStatus(stepStatuses.COMPLETED);

        this.$notify({
          type: 'success',
          text: this.$t('Web.Integrations.ConnectSuccess', { 1: this.$t('Web.Amazon.Title')})
        });
      } catch (e) {
        this.showModal(modalsId.CONNECTOR_ERROR, {
          error: e?.message ?? this.$t('Web.Integrations.ConnectError', { 1: this.$t('Web.Amazon.Title')})
        });
      } finally {
        this.isConnecting = false;
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/style/utils/_mixins.scss";
@import "@/style/utils/_variable.scss";
@import "@/style/utils/_color.scss";

.aws-region-option {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include body-1;
    color: $grey-80;

    &--selected {
      color: $primary-80;
    }
  }

  &__description {
    margin-top: 4px;

    @include body-3;
    color: $grey-60;

    white-space: pre-line;
  }
}
</style>
