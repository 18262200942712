<template>
  <SubPageWrapper empty-cols>
    <ContentBlock>
      <IntegrationContent>
        <template #title>
          <div class="title-wrapper">
            {{ $t('Main.Ui.acImportFromNavDb') }}
            <SlLink
              :href="GUIDE_DYNAMICS_NAV_CONNECTION_URL"
              target="_blank"
            >
              <template #prepend>
                <icon
                  data="@icons/question.svg"
                  class="fill-off size-16"
                />
              </template>
              {{ $t('Web.DbImport.LinkHelp') }}
            </SlLink>
          </div>
        </template>

        <ValidationObserver
          ref="observer"
          slim
        >
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="exportUrl"
              rules="required"
            >
              <SlInput
                v-model="exportUrl"
                :label="$t('DbInsideImport.Ui.lbExportOrders')"
                :disabled="isConnecting"
                :is-invalid="invalid"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="username"
              rules="required"
            >
              <SlInput
                v-model="username"
                :label="$t('Web.DbRelatedConnectors.Form.LabelWinUsername')"
                :disabled="isConnecting"
                :is-invalid="invalid"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50 mb-0">
            <SlValidate
              v-slot="{ invalid }"
              vid="password"
              rules="required"
            >
              <SlPasswordInput
                v-model="password.value"
                :label="$t('Web.DbRelatedConnectors.Form.LabelWinPassword')"
                :disabled="isConnecting"
                :is-set="password.isSet"
                :is-invalid="invalid"
                required
              />
            </SlValidate>
          </div>
        </ValidationObserver>
      </IntegrationContent>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import { modal } from '@/mixins/modal';
import { stepStatuses } from '@/config/integrations';
import { GUIDE_DYNAMICS_NAV_CONNECTION_URL } from '@/config/shared/resources.config';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'SetUp',
  components: {
    IntegrationContent
  },
  mixins: [modal],
  data() {
    return {
      GUIDE_DYNAMICS_NAV_CONNECTION_URL,
      exportUrl: '',
      username: '',
      password: {
        value: '',
        isSet: false
      },
      isConnecting: false
    };
  },
  inject: ['resetStepsToCurrent'],
  computed: {
    ...mapState({
      configuration: state => state.integrations.settings.configuration
    }),
    ...mapGetters({
      activeStep: 'integrations/activeStep',
      isAllStepsCompleted: 'integrations/isAllStepsCompleted'
    })
  },
  created() {
    Object.keys(this.configuration).forEach(key => {
      if (this[key] !== undefined) {
        this[key] = this.configuration[key];
      }
    });
  },
  methods: {
    ...mapActions({
      setActiveStepStatus: 'integrations/setActiveStepStatus',
      testDatasourceConnection: 'integrations/testDatasourceConnection',
      updateIntegrationSettings: 'integrations/updateIntegrationSettings'
    }),
    getConnectionSettings() {
      return {
        exportUrl: this.exportUrl,
        username: this.username,
        password: this.password
      };
    },
    async connectToDatasource() {
      try {
        this.isConnecting = true;

        await this.updateIntegrationSettings(this.getConnectionSettings());

        const isConnected = await this.testDatasourceConnection();

        if (!isConnected) {
          return this.showModal(modalsId.CONNECTOR_ERROR, {
            error: this.$t('Web.Integrations.ConnectError', { 1: this.$t('Web.DynamicsNAV.Title')})
          });
        }

        this.setActiveStepStatus(stepStatuses.COMPLETED);

        this.$notify({
          type: 'success',
          text: this.$t('Web.Integrations.ConnectSuccess', { 1: this.$t('Web.DynamicsNAV.Title')})
        });

        return true;
      } catch (e) {
        this.showModal(modalsId.CONNECTOR_ERROR, {
          error: e?.message ?? this.$t('Web.Integrations.ConnectError', { 1: this.$t('Web.DynamicsNAV.Title')})
        });
      } finally {
        this.isConnecting = false;
      }
    },
    async finish() {
      try {
        const valid = await this.$refs.observer.validate();

        if (!valid) {
          return;
        }

        const isConnected = await this.connectToDatasource();

        return isConnected && this.isAllStepsCompleted;
      } catch {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.integration-content {
  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
