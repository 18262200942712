<template>
  <SubPageWrapper empty-cols>
    <ContentBlock>
      <IntegrationContent>
        <template #title>
          {{ $t('Web.Integrations.ConnectionTitle', { 1: $t('Web.CustomApi.Title')}) }}
        </template>
        <ValidationObserver
          ref="observer"
          slim
        >
          <div
            v-if="isDataPulled"
            class="integration-content-row"
          >
            <SlAlert
              :accent="$t('Web.Integrations.Alerts.PullSuccess')"
              type="success"
            />
          </div>
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="username"
              mode="eager"
              rules="required"
            >
              <SlInput
                v-model="username"
                :label="$t('Web.CustomApi.Input.Username')"
                :is-invalid="invalid"
                :disabled="isPulling"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="password"
              mode="eager"
              rules="required"
            >
              <SlPasswordInput
                v-model="password"
                :label="$t('Web.CustomApi.Input.Password')"
                :is-invalid="invalid"
                :disabled="isPulling"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row integration-content-row--column">
            <div class="heading-4-sb grey-100">
              {{ $t('Web.CustomApi.PullDataEndpointsTitle') }}
            </div>
            <div class="body-1 integration-content__subtitle">
              {{ $t('Web.CustomApi.PullDataEndpointsSubTitle') }}
            </div>
          </div>
          <div
            v-for="(endpoint, key) in importEndpointsList"
            :key="key"
            class="integration-content-row w-50"
          >
            <SlValidate
              v-slot="{ invalid }"
              :vid="`${key}Validator`"
              mode="eager"
              rules="sl_url"
            >
              <SlInput
                v-model="endpoints[key]"
                :label="endpoint.label"
                :disabled="isPulling"
                :is-invalid="invalid"
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="pageLimit"
              rules="numeric"
            >
              <SlInput
                v-model="pageLimit"
                :label="$t('Web.CustomApi.Input.PageLimit')"
                :is-invalid="invalid"
                :disabled="isPulling"
                type="number"
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50">
            <ValidationObserver ref="observer">
              <SlValidate
                v-slot="{ invalid }"
                vid="startDate"
                rules="sl_date_between|sl_valid_date"
              >
                <SlDatePicker
                  v-model="startDate"
                  :label="$t('DbInsideImport.Ui.lbStartDate')"
                  :is-invalid="invalid"
                  class="w-160"
                  inline
                />
              </SlValidate>
            </ValidationObserver>
          </div>
          <div class="integration-content-row">
            <SlCheckbox
              v-model="allowUntrustedCert"
              :label="$t('Web.BaseConnectors.Form.allowUntrustedCert')"
            >
              <SlInfoButton
                v-tooltip="allowSelfSignedCertTooltip"
              />
            </SlCheckbox>
          </div>
        </ValidationObserver>
      </IntegrationContent>
      <template #footer>
        <SlButton
          v-if="!isDataPulled"
          @click="handlePullData"
        >
          {{ $t('Web.Integrations.Actions.BtnPullData') }}
        </SlButton>
        <SlButton
          v-else
          variant="secondary"
          color="grey"
          @click="handleRePull"
        >
          {{ $t('Web.Integrations.Actions.BtnRePull') }}
        </SlButton>
      </template>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import logger from '@/api/logger';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { stepStatuses } from '@/config/integrations';
import { importEndpointsList } from '@/config/integrations/customapi.config';
import { getSplitTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'PullData',
  components: {
    IntegrationContent
  },
  mixins: [modal],
  inject: ['resetStepsToCurrent'],
  data() {
    return {
      importEndpointsList,
      isPulling: false,
      username: '',
      password: '',
      pageLimit: 10000,
      allowUntrustedCert: false,
      startDate: null,
      endpoints: {
        bom: '',
        itemInfo: '',
        toReceive: '',
        toShip: '',
        transactions: '',
        suppliers: ''
      }
    };
  },
  computed: {
    ...mapState({
      configuration: state => state.integrations.settings.configuration
    }),
    ...mapGetters({
      activeStep: 'integrations/activeStep'
    }),
    isDataPulled() {
      return this.activeStep.status === stepStatuses.COMPLETED;
    },
    allowSelfSignedCertTooltip() {
      return getSplitTooltip({
        title: this.$t('Web.BaseConnectors.Form.allowUntrustedCertTooltipTitle'),
        body: `<ul style="list-style:disc; white-space: normal">
          <li style="margin-left: 20px">${this.$t('Web.BaseConnectors.Form.allowUntrustedCertTooltipBody1')}</li>
          <li style="margin-left: 20px">${this.$t('Web.BaseConnectors.Form.allowUntrustedCertTooltipBody2')}</li>
        </ul>`
      });
    },
    isEndpointsEmpty() {
      return !Object.values(this.endpoints).some(value => value);
    }
  },
  beforeMount() {
    Object.keys(this.configuration).forEach(key => {
      if (this[key] !== undefined) {
        this[key] = this.configuration[key];
      }
    });
  },
  methods: {
    ...mapActions({
      setActiveStepStatus: 'integrations/setActiveStepStatus',
      updateIntegrationSettings: 'integrations/updateIntegrationSettings',
      importIntegration: 'integrations/importIntegration',
      resetTransformState: 'integrations/transform/resetState',
      subscribe: 'operations/subscribe'
    }),
    getConfigurationSettings() {
      return {
        username: this.username,
        password: this.password,
        pageLimit: this.pageLimit,
        allowUntrustedCert: this.allowUntrustedCert,
        endpoints: this.endpoints,
        startDate: this.startDate
      };
    },
    handleRePull() {
      this.resetStepsToCurrent(() => {
        this.resetTransformState();
        this.handlePullData();
      });
    },
    async handlePullData() {
      try {
        this.isPulling = true;

        const valid = await this.$refs.observer.validate();

        if (!valid) {
          return;
        }

        if (this.isEndpointsEmpty) {
          this.$notify({
            type: 'error',
            title: this.$t('Web.Error.EndpointsErrorTitle'),
            text: this.$t('Web.Error.EndpointsError')
          });

          return;
        }

        this.showModal(modalsId.SL_LOADING_MODAL, {
          title: this.$t('Web.Integrations.Modals.TitlePullingData'),
          text: this.$t('Web.Integrations.Modals.TextPullingData')
        });

        await this.updateIntegrationSettings(this.getConfigurationSettings());

        await this.subscribe({
          subscriber: this.importIntegration
        });

        this.setActiveStepStatus(stepStatuses.COMPLETED);
      } catch (e) {
        logger.formatAndWriteError({
          e,
          from: 'customApi/handlePullData'
        });

        const message = e?.message ?? this.$t('Web.Integrations.Modals.TextPullingDataError');

        this.showModal(modalsId.SL_ERROR_MODAL, {
          title: this.$t('Web.Integrations.Modals.TitlePullingDataError'),
          text: message
        });
      } finally {
        setTimeout(() => {
          this.hideModal(modalsId.SL_LOADING_MODAL);
          this.isPulling = false;
        }, 150);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .content-block {
  &--footer .content-block__main {
    height: calc(100% - 100px) !important;
  }

  &__footer {
    justify-content: flex-start;

    padding: 8px 0 8px 24px;
  }
}
</style>
