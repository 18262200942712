var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SubPageWrapper',{attrs:{"empty-cols":""}},[_c('ContentBlock',[_c('IntegrationContent',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.ConnectionTitle', { 1: _vm.$t('Web.Amazon.Title')}))+" ")]},proxy:true},{key:"actions",fn:function(){return [_c('SlButton',{attrs:{"loading":_vm.isConnecting},on:{"click":_vm.handleConnect}},[_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.ButtonConnect'))+" ")])]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""}},[_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"clientId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.Amazon.ClientId'),"is-invalid":invalid,"disabled":_vm.isConnecting,"required":""},model:{value:(_vm.clientId),callback:function ($$v) {_vm.clientId=$$v},expression:"clientId"}})]}}])})],1),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"clientSecret","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlPasswordInput',{attrs:{"label":_vm.$t('Web.Amazon.ClientSecret'),"is-invalid":invalid,"disabled":_vm.isConnecting,"required":""},model:{value:(_vm.clientSecret),callback:function ($$v) {_vm.clientSecret=$$v},expression:"clientSecret"}})]}}])})],1),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"refreshToken","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlPasswordInput',{attrs:{"label":_vm.$t('Web.Amazon.RefreshToken'),"is-invalid":invalid,"disabled":_vm.isConnecting,"required":""},model:{value:(_vm.refreshToken),callback:function ($$v) {_vm.refreshToken=$$v},expression:"refreshToken"}})]}}])})],1),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"awsRegion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlSelect',{attrs:{"options":_vm.awsRegionOptions,"select-label":_vm.$t('Web.Amazon.AWSRegion'),"is-invalid":invalid,"disabled":_vm.isConnecting,"required":""},scopedSlots:_vm._u([{key:"selectOption",fn:function(ref){
var option = ref.option;
var selected = ref.selected;
return [_c('div',{staticClass:"aws-region-option"},[_c('div',{staticClass:"aws-region-option__label",class:{
                      'aws-region-option__label--selected': selected
                    }},[_vm._v(" "+_vm._s(option.label)+" "),(selected)?_c('icon',{staticClass:"fill-off size-16 color-primary-80 check-icon",attrs:{"data":require("@icons/check.svg")}}):_vm._e()],1),_c('div',{staticClass:"aws-region-option__description"},[_vm._v(" "+_vm._s(option.description)+" ")])])]}}],null,true),model:{value:(_vm.awsRegion),callback:function ($$v) {_vm.awsRegion=$$v},expression:"awsRegion"}})]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }