<template>
  <SubPageWrapper empty-cols>
    <ContentBlock>
      <IntegrationContent>
        <template #title>
          {{ $t('Web.Integrations.Actions.BtnPullData') }}
        </template>
        <div
          v-if="isDataPulled"
          class="integration-content-row"
        >
          <SlAlert
            :accent="$t('Web.Integrations.Alerts.PullSuccess')"
            type="success"
          />
        </div>
        <ValidationObserver ref="observer">
          <div class="integration-content-row mb-20">
            <SlValidate
              v-slot="{ invalid }"
              vid="startDate"
              rules="sl_date_between|sl_valid_date"
            >
              <SlDatePicker
                v-model="startDate"
                :label="$t('DbInsideImport.Ui.lbStartDate')"
                :is-invalid="invalid"
                class="w-160"
                inline
              />
            </SlValidate>
          </div>
        </ValidationObserver>
        <template #actions>
          <SlButton
            v-if="!isDataPulled"
            @click="handlePullData"
          >
            {{ $t('Web.Integrations.Actions.BtnPullData') }}
          </SlButton>
          <SlButton
            v-else
            variant="secondary"
            color="grey"
            @click="handleRePull"
          >
            {{ $t('Web.Integrations.Actions.BtnRePull') }}
          </SlButton>
        </template>
      </IntegrationContent>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import logger from '@/api/logger';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { stepStatuses } from '@/config/integrations';

export default {
  name: 'PullData',
  components: {
    IntegrationContent
  },
  mixins: [modal],
  inject: ['resetStepsToCurrent'],
  computed: {
    ...mapState({
      configuration: state => state.integrations.settings.configuration
    }),
    ...mapGetters({
      activeStep: 'integrations/activeStep'
    }),
    isDataPulled() {
      return this.activeStep.status === stepStatuses.COMPLETED;
    },
    startDate: {
      get() {
        return this.configuration.startDate;
      },
      set(value) {
        this.updateConfiguration({
          startDate: value
        });
      }
    }
  },
  methods: {
    ...mapActions({
      updateConfiguration: 'integrations/settings/updateConfiguration',
      setActiveStepStatus: 'integrations/setActiveStepStatus',
      updateIntegrationSettings: 'integrations/updateIntegrationSettings',
      importIntegration: 'integrations/importIntegration',
      resetTransformState: 'integrations/transform/resetState',
      subscribe: 'operations/subscribe'
    }),
    handleRePull() {
      this.resetStepsToCurrent(() => {
        this.resetTransformState();
        this.handlePullData();
      });
    },
    async handlePullData() {
      try {
        const valid = await this.$refs.observer.validate();

        if (!valid) {
          return;
        }

        this.showModal(modalsId.SL_LOADING_MODAL, {
          title: this.$t('Web.Integrations.Modals.TitlePullingData'),
          text: this.$t('Web.Integrations.Modals.TextPullingData')
        });
        await this.updateIntegrationSettings();

        await this.subscribe({
          subscriber: this.importIntegration
        });
        this.setActiveStepStatus(stepStatuses.COMPLETED);
      } catch (e) {
        logger.formatAndWriteError({
          e,
          from: 'cin7core/handlePullData'
        });

        const message = e?.message ?? this.$t('Web.Integrations.Modals.TextPullingDataError');

        this.showModal(modalsId.SL_ERROR_MODAL, {
          title: this.$t('Web.Integrations.Modals.TitlePullingDataError'),
          text: message
        });
      } finally {
        setTimeout(() => {
          this.hideModal(modalsId.SL_LOADING_MODAL);
        }, 150);
      }
    }
  }
};
</script>
