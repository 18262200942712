<template>
  <SubPageWrapper empty-cols>
    <ContentBlock>
      <IntegrationContent>
        <template #title>
          {{ $t('Web.Integrations.ConnectionTitle', { 1: $t('Web.QB.Title')}) }}
        </template>

        <div class="integration-content-row">
          <SlAlert
            :accent="$t('Web.QB.steps')"
            type="info"
          >
            <template #text>
              <ol>
                <li>{{ $t('Web.QB.stepOpen') }}</li>
                <li>{{ $t('Web.QB.stepFile') }}</li>
                <li>{{ $t('Web.QB.stepDialog') }}</li>
              </ol>
            </template>
          </SlAlert>
        </div>
        <div class="integration-content-row integration-content-row--column mb-0">
          <div class="input-label body-1 grey-70">
            {{ $t('Web.QB.filepath') }}
          </div>
          <ValidationObserver
            ref="observer"
            slim
          >
            <SlValidate
              v-slot="{ invalid }"
              vid="filePath"
              mode="eager"
              rules="required"
            >
              <SlInput
                v-model.trim="filePathModel"
                :is-invalid="invalid"
                :placeholder="$t('Web.File.filepathPlaceholder')"
                @keydown.enter="handleConnect"
              />
            </SlValidate>
          </ValidationObserver>
        </div>
        <template
          v-if="!isExport"
          #actions
        >
          <SlButton @click="handleConnect">
            {{ $t('Web.DbImport.ButtonConnect') }}
          </SlButton>
        </template>
      </IntegrationContent>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { integrationModuleTypes, stepStatuses } from '@/config/integrations';

export default {
  name: 'Connect',
  components: {
    IntegrationContent
  },
  mixins: [modal],
  data() {
    return {
      modalsId,
      filepath: ''
    };
  },
  inject: ['resetStepsToCurrent'],
  computed: {
    ...mapState({
      activeIntegration: state => state.integrations.active_integration || {},
      settingsStore: state => state.integrations.settings,
      connection: state => state.integrations.settings.connection
    }),
    ...mapGetters({
      activeStep: 'integrations/activeStep',
      isAllStepsCompleted: 'integrations/isAllStepsCompleted'
    }),
    filePathModel: {
      get() {
        return this.filepath;
      },
      set(value) {
        this.filepath = value.replace(/"/g, '');
      }
    },
    isExport() {
      return this.activeIntegration.module === integrationModuleTypes.EXPORT;
    }
  },
  created() {
    this.filepath = this.connection?.filepath || '';
  },
  methods: {
    ...mapActions({
      setActiveStepStatus: 'integrations/setActiveStepStatus',
      updateConnectionSettings: 'integrations/updateConnectionSettings',
      testDatasourceConnection: 'integrations/testDatasourceConnection',
      updateIntegrationSettings: 'integrations/updateIntegrationSettings',
      resetTransformState: 'integrations/transform/resetState'
    }),
    async handleConnect() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        return;
      }

      if (this.activeStep.status === stepStatuses.COMPLETED) {
        return this.resetStepsToCurrent(() => {
          this.resetTransformState();
          this.connectToQb();
        });
      }

      this.connectToQb();
    },
    async connectToQb() {
      if (!this.filepath) {
        return this.showModal(modalsId.SL_ERROR_MODAL, {
          title: this.$t('Error.ConnError'),
          text: this.$t('Web.QB.filepath')
        });
      }

      try {
        this.showModal(modalsId.SL_LOADING_MODAL, {
          title: this.$t('Web.QB.TitleConnect'),
          text: this.$t('Web.QB.TextConnect')
        });

        await this.updateConnectionSettings({ filepath: this.filepath });
        await this.testDatasourceConnection();

        this.setActiveStepStatus(stepStatuses.COMPLETED);

        this.$notify({
          type: 'success',
          text: this.$t('Web.QB.success')
        });

        return true;
      } catch (e) {
        this.showModal(modalsId.SL_ERROR_MODAL, {
          title: this.$t('Error.ConnError'),
          text: e?.message
        });
      } finally {
        setTimeout(() => {
          this.hideModal(modalsId.SL_LOADING_MODAL);
        }, 150);
      }
    },
    // used for export
    async finish() {
      try {
        const isConnected = await this.connectToQb();

        if (!isConnected) {
          return false;
        }

        return this.isAllStepsCompleted;
      } catch {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
ol {
  padding-left: 12px;
  list-style-type: decimal;
}

.input-label {
  margin-bottom: 12px;
}
</style>
