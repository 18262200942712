var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('IntegrationContent',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.TabInvReport'))+" ")]},proxy:true}])},[_c('IntegrationQuery',{attrs:{"label":_vm.$t('Web.DbImport.LabelQuery'),"placeholder":_vm.$t('Web.DbImport.PlaceholderQueryExport'),"stubs":_vm.stubs},model:{value:(_vm.queryModel),callback:function ($$v) {_vm.queryModel=$$v},expression:"queryModel"}}),_c('IntegrationContent',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.TitleTestExport'))+" ")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.TextTestExport'))+" ")]},proxy:true}])},[_c('div',{staticClass:"integration-content-row"},[_c('SlValidate',{attrs:{"vid":"itemCode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{staticClass:"w-240",attrs:{"label":_vm.$t('DbImportDialog.Ui.lbDebugCode'),"is-invalid":invalid},model:{value:(_vm.itemCodeModel),callback:function ($$v) {_vm.itemCodeModel=$$v},expression:"itemCodeModel"}})]}}])})],1),_c('div',{staticClass:"integration-content-row"},[_c('SlInput',{staticClass:"w-240",attrs:{"label":_vm.$t('DbImportDialog.Ui.lbDebugReportLocation')},model:{value:(_vm.locationModel),callback:function ($$v) {_vm.locationModel=$$v},expression:"locationModel"}})],1),_c('div',{staticClass:"integration-content-row integration-content-row--split-inline"},[_c('SlValidate',{attrs:{"vid":"onHand","rules":"required|min_value:0|max_value:1000000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbImportDialog.Ui.lbDebugReportOnHand'),"type":"number","is-invalid":invalid,"inline":""},model:{value:(_vm.onHandModel),callback:function ($$v) {_vm.onHandModel=$$v},expression:"onHandModel"}})]}}])}),_c('SlValidate',{attrs:{"vid":"supplyDays","rules":"required|min_value:0|max_value:1000000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbImportDialog.Ui.lbDebugReportDays'),"type":"number","is-invalid":invalid,"inline":""},model:{value:(_vm.supplyDaysModel),callback:function ($$v) {_vm.supplyDaysModel=$$v},expression:"supplyDaysModel"}})]}}])}),_c('SlValidate',{attrs:{"vid":"receiveQty","rules":"required|min_value:0|max_value:1000000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbImportDialog.Ui.lbDebugReportInTransition'),"type":"number","is-invalid":invalid,"inline":""},model:{value:(_vm.receiveQtyModel),callback:function ($$v) {_vm.receiveQtyModel=$$v},expression:"receiveQtyModel"}})]}}])})],1),_c('SlButton',{attrs:{"variant":"secondary","color":"grey","type":"submit","data-test-export":""}},[_vm._v(" "+_vm._s(_vm.$t('DbImportDialog.Ui.btDebugForecastReportExec'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }