<template>
  <SlModal
    :id="id"
    :width="420"
    no-header
    @created="onCreated"
    @hide="onModalHide"
  >
    <div class="modal-content connection-timeout-error">
      <icon
        data="@icons/style_error_double.svg"
        class="fill-off size-48"
      />
      <div class="connection-timeout-error__title heading-4-sb grey-90">
        {{ $t('Web.Modals.TitleConnectionTimeout') }}
      </div>
      <div class="body-1 grey-70">
        {{ $t('Web.Modals.TextConnectionTimeout', { 1: source }) }}
      </div>
    </div>
    
    <template #footer>
      <SlModalFooter>
        <SlButton
          full-width
          @click.stop="handleClose"
        >
          {{ $t('Common.Ok') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';

export default {
  name: 'ConnectionTimeoutModal',
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      source: null,
      closeCallback: null
    };
  },
  methods: {
    onModalHide() {
      this.closeCallback && this.closeCallback();

      this.source = null;
      this.closeCallback = null;
    },
    handleClose() {
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/connection-timeout-error-modal.scss";
</style>
