<template>
  <SubPageWrapper>
    <template #left-col>
      <SlTabList
        v-if="isODBCDriver"
        v-model="tabModel"
        :tabs="settingTabs"
      />
      <SlTabList
        v-model="tabModel"
        :tabs="exportTabs"
      >
        <template #header>
          {{ $t('Web.Integrations.TitleExport') }}
        </template>
      </SlTabList>
    </template>
    <slot name="header" />
    <ContentBlock>
      <SlTabContent
        v-for="tab in renderedTabs"
        :key="tab.value"
        :value="tab.value"
        :tab-value="tabModel"
      >
        <ValidationObserver
          :ref="`${tab.value}-observer`"
          tag="form"
          @submit.prevent.stop="handleTabSubmit"
        >
          <component
            :is="tab.component"
            :title="tab.label"
            :tab="tab.value"
            :stubs="tabStubs"
          />
        </ValidationObserver>
        <TablePreview :tab="tabModel" />
      </SlTabContent>
    </ContentBlock>
    <template #right-col>
      <TablesInfo
        v-if="tables.length"
        :tables="tables"
        :is-integration="!isAggregation"
      />
    </template>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import TablesInfo from '@/components/Integrations/Modules/Transform/TablesInfo.vue';
import TablePreview from '@/components/Integrations/Modules/Transform/TablePreview.vue';
import Reports from '@/components/Integrations/Modules/Transform/Export/Tabs/Reports.vue';
import InventoryForecast from '@/components/Integrations/Modules/Transform/Export/Tabs/InventoryForecast.vue';
import InventoryReport from '@/components/Integrations/Modules/Transform/Export/Tabs/InventoryReport.vue';
import MinMax from '@/components/Integrations/Modules/Transform/Export/Tabs/MinMax.vue';
import PlannedOrders from '@/components/Integrations/Modules/Transform/Export/Tabs/PlannedOrders.vue';
import Settings from '@/components/Integrations/Modules/Transform/Export/Tabs/Settings.vue';
import { transformValidation } from '@/mixins/integrations/transform/transformValidation.mixin';
import { transformShared } from '@/mixins/integrations/transform/transformShared.mixin';
import { exportTabs, exportTabKeys, exportSettingTabs } from '@/config/integrations/transform.config';
import { dbConnectionTypes } from '@/config/connection/database.config';

export default {
  name: 'ExportTransform',
  components: {
    TablePreview,
    IntegrationContent,
    TablesInfo,
    // tabs
    Reports,
    InventoryForecast,
    InventoryReport,
    MinMax,
    PlannedOrders,
    Settings
  },
  mixins: [transformValidation, transformShared],
  props: {
    requiredTabKeys: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      tabModel: null
    };
  },
  computed: {
    ...mapState({
      activeIntegration: state => state.integrations.active_integration || {},
      transformState: state => state.integrations.transform.export,
      connection: state => state.integrations.settings.connection
    }),
    settingTabs() {
      return exportSettingTabs(this, this.activeIntegration);
    },
    exportTabs() {
      if (!this.activeIntegration.exportType) {
        return [];
      }

      return exportTabs(this, { exportType: this.activeIntegration.exportType }).map(tab => ({
        ...tab,
        required: tab.required ?? this.requiredTabKeys.includes(tab.value)
      }));
    },
    renderedTabs() {
      return [
        ...this.settingTabs,
        ...this.exportTabs
      ];
    },
    isODBCDriver() {
      return this.connection.type !== dbConnectionTypes.MYSQL;
    }
  },
  watch: {
    tabModel(value) {
      const tabData = this.transformState[value];

      if (exportTabKeys.includes(value) && !tabData?.stubs?.length) {
        this.fetchExportStubs(value);
      }
    }
  },
  async created() {
    try {
      this.setIsLoading(true);

      await Promise.allSettled([
        this.fetchTransformSettings(),
        this.fetchTables()
      ]);
    } finally {
      this.setIsLoading(false);
    }
  },
  methods: {
    ...mapActions({
      fetchTables: 'integrations/transform/fetchTables',
      fetchTransformSettings: 'integrations/transform/fetchTransformSettings',
      confirmTransformation: 'integrations/transform/confirmTransformation',
      fetchExportStubs: 'integrations/transform/export/fetchExportStubs',
      testExport: 'integrations/transform/export/testExport',
      updateTabSettings: 'integrations/transform/export/updateTabSettings'
    }),
    setSettings(payload) {
      this.updateTabSettings({
        tab: this.tabModel,
        ...payload
      });
    },
    // actions
    async handleTabSubmit(e) {
      const valid = await this.$sl_validateActiveTab();

      if (!valid) {
        return;
      }

      if (e.submitter.hasAttribute(['data-test-export'])) {
        return this.handleTestExport();
      }
    },
    async handleTestExport() {
      try {
        const result = await this.testExport(this.tabModel);

        if (result) {
          this.$notify({
            type: 'success',
            title: this.$t('Web.Notification.TitleExportSuccess')
          });
        }
      } catch (e) {
        if (!e?.message) {
          return;
        }

        this.$notify({
          type: 'error',
          title: this.$t('Error.HeadDb'),
          text: e.message,
          duration: -1
        });
      }
    },
    async finish() {
      const valid = await this.$sl_validateFilledTabs();

      if (!valid) {
        return;
      }

      try {
        this.setIsLoading(true);

        const success = await this.confirmTransformation();

        return success;
      } finally {
        this.setIsLoading(false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .content-block__main {
    height: 100%;

    .sl-tab-content {
      inset: 24px;

      height: fit-content;
      overflow: visible;
    }
  }
}
</style>
