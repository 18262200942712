<template>
  <SubPageWrapper empty-cols>
    <template #left-col>
      <SlTabList
        v-model="tabModel"
        :tabs="settingTabs"
      />
      <SlTabList
        v-model="tabModel"
        :tabs="importTabs"
        :disabled="tabsDisabled"
      >
        <template #header>
          {{ $t('Web.Transactional.TabsImport') }}
        </template>
      </SlTabList>
    </template>
    <ContentBlock>
      <SlAlert
        v-if="warningText"
        type="warn"
        :accent="warningText"
      />
      <SlTabContent
        v-for="tab in allTabs"
        :key="tab.value"
        :value="tab.value"
        :tab-value="tabModel"
      >
        <ValidationObserver
          :ref="`${tab.value}-observer`"
          slim
        >
          <component
            :is="tab.component"
            :tab-key="tab.value"
            :tab-title="tab.label"
          />
        </ValidationObserver>
      </SlTabContent>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ConnectorContentWrapper from '@/components/Connections/Common/ConnectorContentWrapper';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import Settings from '@/components/Integrations/Modules/Spreadsheet/Tabs/Settings.vue';
import Import from '@/components/Integrations/Modules/Spreadsheet/Tabs/Import.vue';
import modalsId from '@/config/shared/modalsId.config';
import { settingTabs, importTabs, tabKeys, matchKeys } from '@/config/integrations/spreadsheet.config';
import { preventTabClose } from '@/helpers/shared/webAPI';
import { dealWith } from '@/api/helpers/errorRegistry';

export default {
  name: 'Transform',
  components: {
    ConnectorContentBlock,
    ConnectorContentWrapper,
    Settings,
    Import
  },
  data() {
    return {
      modalsId,
      tabsDisabled: false,
      warningSensitiveKeys: [
        'csvDelimiter',
        'composeDate',
        'headerRowsCount'
      ],
      settingTabs: settingTabs(this)
    };
  },
  provide() {
    return {
      setSpreadsheetIntegrationSettings: this.updateSettingsCallback
    };
  },
  inject: ['setIsLoading'],
  computed: {
    ...mapState({
      connectorState: (state) => state.integrations.spreadsheet,
      warningText: (state) => state.integrations.spreadsheet.warning,
      tab: (state) => state.integrations.spreadsheet.tab,
      matchedSheets: (state) => state.integrations.spreadsheet.match_sheets
    }),
    ...mapGetters({
      dataByTab: 'integrations/spreadsheet/dataByTab',
      isCsv: 'integrations/spreadsheet/isCsv',
      isMultipleFiles: 'integrations/spreadsheet/isMultipleFiles',
      isIntegrationEditing: 'integrations/isIntegrationEditing'
    }),
    tabModel: {
      get() {
        return this.tab;
      },
      set(value) {
        this.setTab(value);
      }
    },
    tabData() {
      return this.dataByTab(this.tabModel);
    },
    importTabs() {
      return this.disableTabs(this.filterTabs(importTabs(this)), (tab) => {
        if (!this.isMultipleFiles && this.isCsv) {
          return false;
        }

        return this.matchedSheets[tab.value][matchKeys.SHEET] === null;
      });
    },
    allTabs() {
      return [
        ...this.settingTabs,
        ...this.importTabs
      ];
    },
    requiredTabs() {
      return this.importTabs.filter(tab => tab.required);
    },
    matchedTabs() {
      return this.importTabs.filter(tab => !tab.disabled);
    }
  },
  async beforeMount() {
    try {
      this.setIsLoading(true);

      if (!this.isIntegrationEditing) {
        this.fetchBasicSettings();
      }

      await Promise.allSettled([
        this.fetchAvailableSlots(),
        this.fetchWarnings()
      ]);
    } finally {
      this.setIsLoading(false);
    }
  },
  methods: {
    ...mapActions({
      setTab: 'integrations/spreadsheet/setTab',
      updateTabSettings: 'integrations/spreadsheet/updateTabSettings',
      fetchTablePreview: 'integrations/spreadsheet/fetchTablePreview',
      setTablePreviewResult: 'integrations/spreadsheet/setTablePreviewResult',
      fetchWarnings: 'integrations/spreadsheet/fetchWarnings',
      resetIsTabsLoaded: 'integrations/spreadsheet/resetIsTabsLoaded',
      fetchAvailableSlots: 'integrations/spreadsheet/fetchAvailableSlots',
      fetchBasicSettings: 'integrations/spreadsheet/fetchBasicSettings',
      saveSettings: 'integrations/spreadsheet/saveSettings',
      importIntegration: 'integrations/importIntegration',
      subscribe: 'operations/subscribe',
      logout: 'user/logout'
    }),
    filterTabs(tabs) {
      if (!this.isMultipleFiles && this.isCsv) {
        return tabs.filter(tab => this.matchedSheets[tab.value][matchKeys.SHEET] !== null);
      }

      return tabs;
    },
    disableTabs(tabs, isDisabled) {
      return tabs.map(tab => {
        if (isDisabled(tab)) {
          return {
            ...tab,
            disabled: true
          };
        }

        return tab;
      });
    },
    shouldCheckWarnings(key) {
      return this.warningSensitiveKeys.includes(key);
    },
    async updateSettingsCallback(payload) {
      preventTabClose(true);

      await this.updateTabSettings({
        tab: this.tabModel,
        ...payload
      });

      const isValid = await this.validateSettingsTab();

      if (isValid && this.shouldCheckWarnings(payload.key)) {
        this.fetchWarnings();
      }
    },
    async fetchPreview(tab) {
      try {
        this.updateTabSettings({
          tab,
          key: 'isLoading',
          value: true
        });

        const { operationData } = await this.subscribe({
          subscriber: () => this.fetchTablePreview(tab)
        });

        this.setTablePreviewResult({
          data: operationData,
          tab
        });
      } catch (e) {
        const errorMessage = e?.message;

        if (errorMessage) {
          this.$notify({
            type: 'error',
            text: errorMessage,
            duration: 15000
          });
        }
      } finally {
        this.updateTabSettings({
          tab,
          key: 'isLoaded',
          value: true
        });

        this.updateTabSettings({
          tab,
          key: 'isLoading',
          value: false
        });
      }
    },
    async validateTab() {
      const observer = this.$refs[`${this.tabModel}-observer`][0];

      if (!observer) {
        return true;
      }

      return observer.validate();
    },
    async validateSettingsTab() {
      if (this.tabModel !== tabKeys.SETTINGS) {
        return;
      }

      const isValid = await this.validateTab();

      this.tabsDisabled = !isValid;
      this.resetIsTabsLoaded();

      return isValid;
    },
    checkUnmatchedSlots(tabs) {
      let result = null;

      tabs.find(({ value: tabKey }) => {
        const tabData = this.dataByTab(tabKey);
        const matchedSlots = Object.values(tabData.matchedSlots);
        const unmatchedSlots = tabData.availableSlots.reduce((acc, slot) => {
          if (!slot.isRequired) {
            return acc;
          }

          if (!matchedSlots.includes(slot.colMeaning)) {
            acc.push(slot.name);
          }

          return acc;
        }, []);

        if (unmatchedSlots.length) {
          result = {
            tab: tabKey,
            slots: unmatchedSlots
          };

          return true;
        }

        return false;
      });

      return result;
    },
    async validateMatchedSlots() {
      const isValid = await this.validateTab();

      if (!isValid) {
        return false;
      }

      const unmatchedSlots = this.checkUnmatchedSlots(this.matchedTabs);

      if (!unmatchedSlots) {
        return true;
      }

      if (unmatchedSlots) {
        const tabData = this.dataByTab(unmatchedSlots.tab);

        if (tabData.isLoaded) {
          this.tabModel = unmatchedSlots.tab;
        } else {
          try {
            await this.fetchPreview(unmatchedSlots.tab);

            return this.validateMatchedSlots();
          } finally {
            this.updateTabSettings({
              tab: unmatchedSlots.tab,
              key: 'isLoaded',
              value: true
            });
          }
        }

        this.$notify({
          type: 'error',
          title: this.$t('Web.DbImport.Errors.NotifyTitleReimport'),
          text: this.$t('Web.Spreadsheet.NotifyMatchColsWarningText', { 1: unmatchedSlots.slots.join(', ') }),
          duration: 15000
        });

        return false;
      }
    },
    validate() {
      return this.validateMatchedSlots();
    },
    async finish() {
      try {
        const isValid = await this.validate();

        if (!isValid) {
          return false;
        }

        this.setIsLoading(true);

        await this.saveSettings();

        await this.subscribe({
          subscriber: () => this.importIntegration()
        });

        return true;
      } catch (e) {
        dealWith({
          '*': {
            title: this.$t('Web.DbImport.Errors.NotifyTitleImport'),
            text: (e) => e.message
          }
        })(e);
      } finally {
        this.setIsLoading(false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .content-block {
    .sl-tab-content {
      inset: 24px;

      height: 100%;

      .integration-content,
      .integration-content__main {
        height: 100%;
        max-height: calc(100% - 38px);
      }
    }  }
}
</style>
