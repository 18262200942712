<template>
  <IntegrationContent>
    <template #title>
      {{ title }}
    </template>
    <SlCheckbox
      v-if="isODBCDriver"
      v-model="legacyExportModeModel"
      :label="$t('Web.DbImport.LabelLegacyExport')"
    />

    <IntegrationContent v-if="isPOExport">
      <template #title>
        {{ $t('Web.DbImport.TitleToReceiveSetting') }}
      </template>
      <template #subtitle>
        {{ $t('Web.DbImport.TextToReceiveSetting') }}
      </template>

      <IntegrationMatch
        :left-label="$t('Web.DbImport.ToReceiveSlValue')"
        :right-label="$t('Web.DbImport.ToReceiveDbValue')"
      >
        <div class="integration-match-row">
          <div class="w-150 body-1 grey-80">
            {{ $t('DbImportDialog.Ui.lbPurchase') }}
          </div>
          <SlInput
            v-model="purchaseModel"
            class="w-240"
            :placeholder="$t('Web.DbImport.PlaceholderToReceiveValue')"
          />
        </div>
        <div class="integration-match-row">
          <div class="w-150 body-1 grey-80">
            {{ $t('DbImportDialog.Ui.lbTransfer') }}
          </div>
          <SlInput
            v-model="transferModel"
            class="w-240"
            :placeholder="$t('Web.DbImport.PlaceholderToReceiveValue')"
          />
        </div>
        <div class="integration-match-row">
          <div class="w-150 body-1 grey-80">
            {{ $t('DbImportDialog.Ui.lbWork') }}
          </div>
          <SlInput
            v-model="manufactureModel"
            class="w-240"
            :placeholder="$t('Web.DbImport.PlaceholderToReceiveValue')"
          />
        </div>
      </IntegrationMatch>
    </IntegrationContent>
  </IntegrationContent>
</template>

<script>
import { mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import IntegrationMatch from '@/components/Integrations/IntegrationMatch.vue';
import { dbConnectionTypes } from '@/config/connection/database.config';
import { integrationExportTypes } from '@/config/integrations';

export default {
  name: 'Settings',
  components: {
    IntegrationMatch,
    IntegrationContent
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    tab: {
      type: String,
      default: ''
    }
  },
  inject: [
    'setIntegrationSettings'
  ],
  computed: {
    ...mapState({
      activeIntegration: state => state.integrations.active_integration,
      transform: state => state.integrations.transform.export,
      connection: state => state.integrations.settings.connection
    }),
    tabData() {
      return this.transform[this.tab] || {};
    },
    isODBCDriver() {
      return this.connection.type !== dbConnectionTypes.MYSQL;
    },
    isPOExport() {
      return this.activeIntegration.exportType === integrationExportTypes.PLANNED_ORDERS;
    },
    legacyExportModeModel: {
      get() {
        return this.tabData.legacyExportMode;
      },
      set(value) {
        this.setIntegrationSettings({ value, key: 'legacyExportMode' });
      }
    },
    purchaseModel: {
      get() {
        return this.tabData.purchase;
      },
      set(value) {
        this.setIntegrationSettings({ value, key: 'purchase' });
      }
    },
    transferModel: {
      get() {
        return this.tabData.transfer;
      },
      set(value) {
        this.setIntegrationSettings({ value, key: 'transfer' });
      }
    },
    manufactureModel: {
      get() {
        return this.tabData.work;
      },
      set(value) {
        this.setIntegrationSettings({ value, key: 'work' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.connector-content-block {
  ::v-deep {
    .connector-content-block__main {
      row-gap: 16px;
    }
  }
}

.connector-content-row:first-child {
  margin-bottom: 8px;
}
</style>
