<template>
  <div class="step-wrapper">
    <div
      class="step"
      :class="{
        'step--active': active,
        'step--completed': completed,
        'step--disabled': disabled,
        'step--no-available': !available
      }"
    >
      <div class="step__status">
        <transition name="fade">
          <icon
            v-if="completed"
            data="@icons/check.svg"
            class="fill-off size-12 color-white-stroke"
          />
        </transition>
      </div>
      <div
        class="step__link"
        @click="$emit('click')"
      >
        <slot />
      </div>
      <div
        v-if="divider"
        class="step__divider"
      />
    </div>
  </div>
</template>

<script>
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'IntegrationStep',
  props: {
    active: Boolean,
    completed: Boolean,
    disabled: Boolean,
    available: Boolean,
    divider: Boolean
  },
  data() {
    return {
      getTooltip
    };
  }
};
</script>

<style scoped lang="scss">
@import '@/style/components/integrations/integration-step';
</style>