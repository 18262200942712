var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SubPageWrapper',{attrs:{"empty-cols":""}},[_c('ContentBlock',{scopedSlots:_vm._u([{key:"footer",fn:function(){return [(!_vm.isDataPulled)?_c('SlButton',{on:{"click":_vm.handlePullData}},[_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.Actions.BtnPullData'))+" ")]):_c('SlButton',{attrs:{"variant":"secondary","color":"grey"},on:{"click":_vm.handleRePull}},[_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.Actions.BtnRePull'))+" ")])]},proxy:true}])},[_c('IntegrationContent',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.ConnectionTitle', { 1: _vm.$t('Web.CustomApi.Title')}))+" ")]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""}},[(_vm.isDataPulled)?_c('div',{staticClass:"integration-content-row"},[_c('SlAlert',{attrs:{"accent":_vm.$t('Web.Integrations.Alerts.PullSuccess'),"type":"success"}})],1):_vm._e(),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"username","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.CustomApi.Input.Username'),"is-invalid":invalid,"disabled":_vm.isPulling,"required":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}])})],1),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"password","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlPasswordInput',{attrs:{"label":_vm.$t('Web.CustomApi.Input.Password'),"is-invalid":invalid,"disabled":_vm.isPulling,"required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}])})],1),_c('div',{staticClass:"integration-content-row integration-content-row--column"},[_c('div',{staticClass:"heading-4-sb grey-100"},[_vm._v(" "+_vm._s(_vm.$t('Web.CustomApi.PullDataEndpointsTitle'))+" ")]),_c('div',{staticClass:"body-1 integration-content__subtitle"},[_vm._v(" "+_vm._s(_vm.$t('Web.CustomApi.PullDataEndpointsSubTitle'))+" ")])]),_vm._l((_vm.importEndpointsList),function(endpoint,key){return _c('div',{key:key,staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":(key + "Validator"),"mode":"eager","rules":"sl_url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":endpoint.label,"disabled":_vm.isPulling,"is-invalid":invalid},model:{value:(_vm.endpoints[key]),callback:function ($$v) {_vm.$set(_vm.endpoints, key, $$v)},expression:"endpoints[key]"}})]}}],null,true)})],1)}),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"pageLimit","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.CustomApi.Input.PageLimit'),"is-invalid":invalid,"disabled":_vm.isPulling,"type":"number"},model:{value:(_vm.pageLimit),callback:function ($$v) {_vm.pageLimit=$$v},expression:"pageLimit"}})]}}])})],1),_c('div',{staticClass:"integration-content-row w-50"},[_c('ValidationObserver',{ref:"observer"},[_c('SlValidate',{attrs:{"vid":"startDate","rules":"sl_date_between|sl_valid_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlDatePicker',{staticClass:"w-160",attrs:{"label":_vm.$t('DbInsideImport.Ui.lbStartDate'),"is-invalid":invalid,"inline":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})]}}])})],1)],1),_c('div',{staticClass:"integration-content-row"},[_c('SlCheckbox',{attrs:{"label":_vm.$t('Web.BaseConnectors.Form.allowUntrustedCert')},model:{value:(_vm.allowUntrustedCert),callback:function ($$v) {_vm.allowUntrustedCert=$$v},expression:"allowUntrustedCert"}},[_c('SlInfoButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.allowSelfSignedCertTooltip),expression:"allowSelfSignedCertTooltip"}]})],1)],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }