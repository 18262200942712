<template>
  <SubPageWrapper empty-cols>
    <ContentBlock>
      <IntegrationContent>
        <template #title>
          {{ $t('Web.Integrations.Steps.setup') }}
        </template>

        <ValidationObserver
          ref="observer"
          slim
        >
          <div class="integration-content-row w-480">
            <SlSelect
              v-model="subsidiary"
              :select-label="$t('Web.Netsuite.LabelSubsidiary')"
              :options="subsidiaryOptions"
              :loading="isLoading"
              track-by="_id"
              label="_name"
              :internal-search="false"
              :placeholder="$t('Web.Netsuite.NoData', { 1: $t('Web.Netsuite.Subsidiary') })"
              allow-empty
              return-object
              searchable
              clearable
              @search-change="(query) => handleSearch({ entity: entities.SUBSIDIARY, query })"
            />
          </div>
          <div class="integration-content-row w-480">
            <SlSelect
              v-model="employee"
              :select-label="$t('Web.Netsuite.LabelEmployee')"
              :options="employeeOptions"
              :loading="isLoading"
              track-by="_id"
              label="_name"
              :internal-search="false"
              :placeholder="$t('Web.Netsuite.NoData', { 1: $t('Web.Netsuite.Employee') })"
              allow-empty
              return-object
              searchable
              clearable
              @search-change="(query) => handleSearch({ entity: entities.EMPLOYEE, query })"
            />
          </div>
        </ValidationObserver>
      </IntegrationContent>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import { modal } from '@/mixins/modal';
import { stepStatuses } from '@/config/integrations';
import { entities } from '@/config/integrations/netsuite.config';
import modalsId from '@/config/shared/modalsId.config';
import { customDebounce } from '@/helpers/shared/listeners';

export default {
  name: 'SetUp',
  components: {
    IntegrationContent
  },
  mixins: [modal],
  data() {
    return {
      entities,
      subsidiary: null,
      employee: null,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      configuration: state => state.integrations.settings.configuration,
      subsidiaryOptions: state => state.integrations.netsuite.subsidiary_list || [],
      employeeOptions: state => state.integrations.netsuite.employee_list || []
    }),
    ...mapGetters({
      isAllStepsCompleted: 'integrations/isAllStepsCompleted'
    })
  },
  created() {
    Object.keys(this.configuration).forEach(key => {
      if (this[key] !== undefined) {
        this[key] = this.configuration[key];
      }
    });

    // initialize the entity list if the configuration is already set
    if (this.subsidiary) {
      this.setEntityList({
        entity: entities.SUBSIDIARY,
        value: [this.subsidiary]
      });
    }

    if (this.employee) {
      this.setEntityList({
        entity: entities.EMPLOYEE,
        value: [this.employee]
      });
    }
  },
  methods: {
    ...mapActions({
      resetState: 'integrations/netsuite/resetState',
      setEntityList: 'integrations/netsuite/setEntityList',
      cacheEntity: 'integrations/netsuite/cacheEntity',
      fetchEntities: 'integrations/netsuite/fetchEntities',
      updateIntegrationSettings: 'integrations/updateIntegrationSettings',
      setActiveStepStatus: 'integrations/setActiveStepStatus',
      switchActiveStep: 'integrations/switchActiveStep'
    }),
    getConfigurationSettings() {
      return {
        subsidiary: this.subsidiary,
        employee: this.employee
      };
    },
    searchEntities: customDebounce(async function({ entity, query }) {
      try {
        await this.cacheEntity(entity);
        await this.fetchEntities({ entity, query });
      } catch {
        this.showModal(modalsId.SL_ERROR_MODAL, {
          title: this.$t('Error.ConnError'),
          text: this.$t('Web.Modals.Error.TextCheckConnectionData'),
          confirmText: this.$t('Web.DbImport.ButtonEditConnection'),
          confirmCallback: () => this.switchActiveStep(0),
          contactSupport: true
        });
      } finally {
        this.isLoading = false;
      }
    }, 500),
    async handleSearch(payload) {
      if (!payload.query) {
        return;
      }

      this.isLoading = true;

      this.searchEntities(payload);
    },
    async finish() {
      try {
        const valid = await this.$refs.observer.validate();

        if (!valid) {
          return;
        }

        await this.updateIntegrationSettings(this.getConfigurationSettings());

        this.setActiveStepStatus(stepStatuses.COMPLETED);
        this.resetState();

        return this.isAllStepsCompleted;
      } catch {
        return false;
      }
    }
  }
};
</script>
