<template>
  <IntegrationContent>
    <template #title>
      {{ $t('Web.DbImport.TabPlannedOrders') }}
    </template>
    <IntegrationQuery
      v-model="queryModel"
      :label="$t('Web.DbImport.LabelQuery')"
      :placeholder="$t('Web.DbImport.PlaceholderQueryExport')"
      :stubs="stubs"
    />
    <IntegrationContent>
      <template #title>
        {{ $t('Web.DbImport.TitleTestExport') }}
      </template>
      <template #subtitle>
        {{ $t('Web.DbImport.TextTestExport') }}
      </template>
      <div class="integration-content-row">
        <SlValidate
          v-slot="{ invalid }"
          vid="itemCode"
          rules="required"
        >
          <SlInput
            v-model="itemCodeModel"
            :label="$t('DbImportDialog.Ui.lbDebugCode')"
            :is-invalid="invalid"
            class="w-240"
          />
        </SlValidate>
      </div>
      <div class="integration-content-row">
        <SlInput
          v-model="locationModel"
          :label="$t('DbImportDialog.Ui.lbDebugLocation')"
          class="w-240"
        />
      </div>
      <div class="integration-content-row integration-content-row--split-inline">
        <SlValidate
          v-slot="{ invalid }"
          vid="orderQty"
          rules="required|min_value:1|max_value:1000000"
        >
          <SlInput
            v-model="orderQtyModel"
            :label="$t('DbImportDialog.OrderQty')"
            type="number"
            :is-invalid="invalid"
            inline
          />
        </SlValidate>
        <SlValidate
          v-slot="{ invalid }"
          vid="reorderPoint"
          rules="required|min_value:0|max_value:1000000"
        >
          <SlInput
            v-model="reorderPointModel"
            :label="$t('DbImportDialog.Ui.lbDebugMinPoint')"
            type="number"
            :is-invalid="invalid"
            inline
          />
        </SlValidate>
      </div>
      <SlButton
        variant="secondary"
        color="grey"
        type="submit"
        data-test-export
      >
        {{ $t('DbImportDialog.Ui.btDebugForecastReportExec') }}
      </SlButton>
    </IntegrationContent>
  </IntegrationContent>
</template>

<script>
import { mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import IntegrationQuery from '@/components/Integrations/IntegrationQuery.vue';

export default {
  name: 'PlannedOrders',
  components: {
    IntegrationContent,
    IntegrationQuery
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    tab: {
      type: String,
      default: ''
    },
    stubs: {
      type: Array,
      default: () => ([])
    }
  },
  inject: [
    'setIntegrationSettings'
  ],
  computed: {
    ...mapState({
      transform: state => state.integrations.transform.export
    }),
    tabData() {
      return this.transform[this.tab] || {};
    },
    queryModel: {
      get() {
        return this.tabData.query;
      },
      set(value) {
        this.setIntegrationSettings({ value, key: 'query' });
      }
    },
    itemCodeModel: {
      get() {
        return this.tabData.itemCode;
      },
      set(value) {
        this.setIntegrationSettings({ value, key: 'itemCode' });
      }
    },
    locationModel: {
      get() {
        return this.tabData.location;
      },
      set(value) {
        this.setIntegrationSettings({ value, key: 'location' });
      }
    },
    orderQtyModel: {
      get() {
        return this.tabData.orderQty ?? 1;
      },
      set(value) {
        this.setIntegrationSettings({ value, key: 'orderQty' });
      }
    },
    reorderPointModel: {
      get() {
        return this.tabData.reorderPoint ?? 0;
      },
      set(value) {
        this.setIntegrationSettings({ value, key: 'reorderPoint' });
      }
    }
  }
};
</script>
