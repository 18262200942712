<template>
  <SubPageWrapper empty-cols>
    <ContentBlock>
      <IntegrationContent>
        <template #title>
          <div class="title-wrapper">
            {{ $t('Main.Ui.acImportFromSapB1') }}
            <SlLink
              :href="GUIDE_SAP_EXPORT_URL"
              target="_blank"
            >
              <template #prepend>
                <icon
                  data="@icons/question.svg"
                  class="fill-off size-16"
                />
              </template>
              {{ $t('Web.DbImport.LinkHelp') }}
            </SlLink>
          </div>
        </template>

        <div class="integration-content-row">
          <SlAlertLine
            type="warn"
            column
          >
            <div class="sl-alert__accent">
              <i18n path="Web.SapB1.AlertTitle">
                <template v-slot:1>
                  <SlLink
                    :href="SAP_PROXY_DOWNLOAD_URL"
                    target="_blank"
                    variant="yellow"
                  >
                    {{ $t('Web.SapB1.AlertLink') }}
                  </SlLink>
                </template>
              </i18n>
            </div>
            <div class="sl-alert__text">
              {{ $t('Web.SapB1.AlertText') }}
            </div>
          </SlAlertLine>
        </div>

        <ValidationObserver
          ref="observer"
          slim
        >
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="database"
              rules="required"
            >
              <SlInput
                v-model="database"
                :label="$t('DbInsideSourceWidget.Ui.lbDatabase')"
                :disabled="isConnecting"
                :is-invalid="invalid"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="serverName"
              rules="required"
            >
              <SlInput
                v-model="serverName"
                :label="$t('Web.DbRelatedConnectors.Form.LabelSapServerName')"
                :disabled="isConnecting"
                :is-invalid="invalid"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="username"
              rules="required"
            >
              <SlInput
                v-model="username"
                :label="$t('Web.DbRelatedConnectors.Form.LabelSapUsername')"
                :disabled="isConnecting"
                :is-invalid="invalid"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="password"
              rules="required"
              mode="eager"
            >
              <SlPasswordInput
                v-model="password.value"
                :label="$t('Web.DbRelatedConnectors.Form.LabelSapPassword')"
                :disabled="isConnecting"
                :is-set="password.isSet"
                :is-invalid="invalid"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="sldAddress"
              rules="required"
            >
              <SlInput
                v-model="sldAddress"
                :label="$t('DbInsideImport.Names.SapSLDAddress')"
                :disabled="isConnecting"
                :is-invalid="invalid"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="clientBitness"
              rules="required"
            >
              <SlSelect
                v-model="clientBitness"
                :options="bitnessOptions"
                :select-label="$t('DbInsideSourceWidget.Ui.lbBitness')"
                :disabled="isConnecting"
                :is-invalid="invalid"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="sqlServerVersion"
              rules="required"
            >
              <SlSelect
                v-model="sqlServerVersion"
                :options="sqlVersionOptions"
                :select-label="$t('DbInsideImport.Ui.lbSqlVersion')"
                :disabled="isConnecting"
                :is-invalid="invalid"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row">
            <SlCheckbox
              v-model="poInsteadTO"
              :label="$t('DbInsideImport.Ui.chPOinsteadTO')"
              :disabled="isConnecting"
            />
          </div>
        </ValidationObserver>
      </IntegrationContent>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import { modal } from '@/mixins/modal';
import { stepStatuses } from '@/config/integrations';
import { GUIDE_SAP_EXPORT_URL, SAP_PROXY_DOWNLOAD_URL } from '@/config/shared/resources.config';
import { bitnessOptions, sqlVersionOptions } from '@/config/integrations/sapb1.config';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'SetUp',
  components: {
    IntegrationContent
  },
  mixins: [modal],
  data() {
    return {
      SAP_PROXY_DOWNLOAD_URL,
      GUIDE_SAP_EXPORT_URL,
      bitnessOptions,
      sqlVersionOptions,
      database: '',
      serverName: '',
      username: '',
      password: {
        value: '',
        isSet: false
      },
      sldAddress: '',
      clientBitness: '',
      sqlServerVersion: '',
      poInsteadTO: false,
      isConnecting: false
    };
  },
  computed: {
    ...mapState({
      configuration: state => state.integrations.settings.configuration
    }),
    ...mapGetters({
      isAllStepsCompleted: 'integrations/isAllStepsCompleted'
    })
  },
  created() {
    Object.keys(this.configuration).forEach(key => {
      if (this[key] !== undefined) {
        this[key] = this.configuration[key];
      }
    });
  },
  methods: {
    ...mapActions({
      updateIntegrationSettings: 'integrations/updateIntegrationSettings',
      testDatasourceConnection: 'integrations/testDatasourceConnection',
      setActiveStepStatus: 'integrations/setActiveStepStatus'
    }),
    getConnectionSettings() {
      return {
        database: this.database,
        serverName: this.serverName,
        username: this.username,
        password: this.password,
        sldAddress: this.sldAddress,
        clientBitness: this.clientBitness,
        sqlServerVersion: this.sqlServerVersion,
        poInsteadTO: this.poInsteadTO
      };
    },
    async connectToDatasource() {
      try {
        this.isConnecting = true;

        await this.updateIntegrationSettings(this.getConnectionSettings());

        const isConnected = await this.testDatasourceConnection();

        if (!isConnected) {
          return this.showModal(modalsId.CONNECTOR_ERROR, {
            error: this.$t('Web.Integrations.ConnectError', { 1: this.$t('Web.SAP.Title')})
          });
        }

        await this.setActiveStepStatus(stepStatuses.COMPLETED);

        this.$notify({
          type: 'success',
          text: this.$t('Web.Integrations.ConnectSuccess', { 1: this.$t('Web.SAP.Title')})
        });

        return true;
      } catch (e) {
        this.showModal(modalsId.CONNECTOR_ERROR, {
          error: e?.message ?? this.$t('Web.Integrations.ConnectError', { 1: this.$t('Web.SAP.Title')})
        });
      } finally {
        this.isConnecting = false;
      }
    },
    async finish() {
      try {
        const valid = await this.$refs.observer.validate();

        if (!valid) {
          return;
        }

        const isConnected = await this.connectToDatasource();

        return isConnected && this.isAllStepsCompleted;
      } catch {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.integration-content {
  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
