var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SubPageWrapper',{attrs:{"empty-cols":""}},[_c('ContentBlock',[_c('IntegrationContent',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.ConnectionTitle', { 1: _vm.$t('Web.DynamicsGP.Title')}))+" ")]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""}},[_c('div',{staticClass:"integration-content-row integration-content-row--split w-50"},[_c('SlValidate',{key:"host",attrs:{"vid":"host","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbInsideSourceWidget.Ui.lbHost'),"disabled":_vm.isConnecting,"is-invalid":invalid,"required":""},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SlInfoButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip(_vm.$t('Web.DbRelatedConnectors.Form.TooltipGpServiceHost'))),expression:"getTooltip($t('Web.DbRelatedConnectors.Form.TooltipGpServiceHost'))"}]})]},proxy:true}],null,true),model:{value:(_vm.host),callback:function ($$v) {_vm.host=$$v},expression:"host"}})]}}])}),_c('SlValidate',{key:"port",staticClass:"integration-content-row--port",attrs:{"vid":"port","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.DbRelatedConnectors.Form.LabelGpPort'),"disabled":_vm.isConnecting,"is-invalid":invalid,"type":"number","required":""},model:{value:(_vm.port),callback:function ($$v) {_vm.port=$$v},expression:"port"}})]}}])})],1),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.DbRelatedConnectors.Form.LabelWinUsername'),"disabled":_vm.isConnecting,"is-invalid":invalid,"required":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}])})],1),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"password","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlPasswordInput',{attrs:{"label":_vm.$t('Web.DbRelatedConnectors.Form.LabelWinPassword'),"disabled":_vm.isConnecting,"is-set":_vm.password.isSet,"is-invalid":invalid,"required":""},model:{value:(_vm.password.value),callback:function ($$v) {_vm.$set(_vm.password, "value", $$v)},expression:"password.value"}})]}}])})],1),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"companyKey","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"type":"number","label":_vm.$t('Web.DbRelatedConnectors.Form.LabelCompanyId'),"disabled":_vm.isConnecting,"is-invalid":invalid,"required":""},model:{value:(_vm.companyKey),callback:function ($$v) {_vm.companyKey=$$v},expression:"companyKey"}})]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }