export const amazonReqionKeys = {
  US_EAST_1: 'us_east_1',
  EU_WEST_1: 'eu_west_1',
  US_WEST_2: 'us_west_2'
};

export const awsRegionOptions = (ctx) => ([
  {
    label: 'us-east-1',
    description: ctx.$t(`Web.Amazon.${amazonReqionKeys.US_EAST_1}`),
    value: amazonReqionKeys.US_EAST_1
  },
  {
    label: 'eu-west-1',
    description: ctx.$t(`Web.Amazon.${amazonReqionKeys.EU_WEST_1}`),
    value: amazonReqionKeys.EU_WEST_1
  },
  {
    label: 'us-west-2',
    description: ctx.$t(`Web.Amazon.${amazonReqionKeys.US_WEST_2}`),
    value: amazonReqionKeys.US_WEST_2
  }
]);