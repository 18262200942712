var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SubPageWrapper',{attrs:{"empty-cols":""}},[_c('ContentBlock',[_c('IntegrationContent',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title-wrapper"},[_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.ConnectionTitle', { 1: _vm.$t('Web.Netsuite.Title')}))+" "),_c('SlLink',{attrs:{"href":_vm.GUIDE_NETSUITE_CONNECTION_URL,"target":"_blank"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-16",attrs:{"data":require("@icons/question.svg")}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.LinkHelp'))+" ")])],1)]},proxy:true},{key:"actions",fn:function(){return [_c('SlButton',{attrs:{"loading":_vm.isConnecting},on:{"click":_vm.handleConnect}},[_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.ButtonConnect'))+" ")])]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""}},[_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"companyId","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"disabled":_vm.isConnecting,"label":_vm.$t('Web.Netsuite.LabelAccountId'),"is-invalid":invalid,"required":""},model:{value:(_vm.companyId),callback:function ($$v) {_vm.companyId=$$v},expression:"companyId"}})]}}])})],1),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"consumerKey","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"disabled":_vm.isConnecting,"label":_vm.$t('Web.Netsuite.LabelConsumerKey'),"is-invalid":invalid,"required":""},model:{value:(_vm.consumerKey),callback:function ($$v) {_vm.consumerKey=$$v},expression:"consumerKey"}})]}}])})],1),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"consumerSecret","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlPasswordInput',{attrs:{"disabled":_vm.isConnecting,"is-set":_vm.consumerSecret.isSet,"label":_vm.$t('Web.Netsuite.LabelConsumerSecret'),"is-invalid":invalid,"required":""},model:{value:(_vm.consumerSecret.value),callback:function ($$v) {_vm.$set(_vm.consumerSecret, "value", $$v)},expression:"consumerSecret.value"}})]}}])})],1),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"accessToken","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"disabled":_vm.isConnecting,"label":_vm.$t('Web.Netsuite.LabelTokenID'),"is-invalid":invalid,"required":""},model:{value:(_vm.accessToken),callback:function ($$v) {_vm.accessToken=$$v},expression:"accessToken"}})]}}])})],1),_c('div',{staticClass:"integration-content-row w-50 mb-0"},[_c('SlValidate',{attrs:{"vid":"tokenSecret","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlPasswordInput',{attrs:{"disabled":_vm.isConnecting,"is-set":_vm.tokenSecret.isSet,"label":_vm.$t('Web.Netsuite.LabelTokenSecret'),"is-invalid":invalid,"required":""},model:{value:(_vm.tokenSecret.value),callback:function ($$v) {_vm.$set(_vm.tokenSecret, "value", $$v)},expression:"tokenSecret.value"}})]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }