<template>
  <SlModal
    :id="id"
    :width="420"
    no-header
    @created="onCreated"
    @hidden="onModalHide"
  >
    <div class="modal-content connection-error">
      <div class="connection-error__icon">
        <icon
          data="@icons/style_error_double.svg"
          class="fill-off size-48"
        />
      </div>
      <div class="connection-error__title heading-4-sb grey-90">
        {{ $t('Error.ConnError') }}
      </div>
      <div class="connection-error__text">
        {{ error }}
      </div>
      <div class="connection-error__text">
        {{ $t('Web.Modals.Error.TextCheckConnectionData') }}
      </div>
      <div class="connection-error__text">
        {{ $t('Web.Modals.Error.TextIfProblemExist') }}
        <SlLink @click="handleOpenChat">
          {{ $t('Web.Modals.Error.ContactSupport') }}
        </SlLink>
      </div>
    </div>

    <template #footer>
      <SlModalFooter>
        <SlButton
          full-width
          @click.stop="handleClose"
        >
          {{ $t('Web.DbImport.ButtonEditConnection') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'DatasourceConnectionError',
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      error: null
    };
  },
  methods: {
    onModalHide() {
      this.error = null;
    },
    handleClose() {
      this.hideModal(this.id);
    },
    handleOpenChat() {
      this.showModal(modalsId.REPORT_ISSUE);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/connection/connectors/shared/connection-error-modal.scss";
</style>
