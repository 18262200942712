var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SubPageWrapper',{attrs:{"empty-cols":""}},[_c('ContentBlock',[_c('IntegrationContent',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.ConnectionTitle', { 1: _vm.$t('Web.Transactional.Title')}))+" ")]},proxy:true},{key:"subtitle",fn:function(){return [(_vm.isFreePlan)?_c('i18n',{attrs:{"path":"Web.Transactional.GetStarted","tag":"p"},scopedSlots:_vm._u([{key:"1",fn:function(){return [_c('SlLink',{attrs:{"href":_vm.FREE_VERSION_TUTORIAL_URL,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('Web.Transactional.WatchVideo'))+" ")])]},proxy:true}],null,false,1820020520)}):_vm._e(),_c('i18n',{attrs:{"path":"Web.Transactional.DownloadTemplate"},scopedSlots:_vm._u([{key:"1",fn:function(){return [_c('SlLink',{attrs:{"href":_vm.SPREADSHEET_TRANSACTIONAL_TEMPLATE_URL,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('Web.Transactional.DataTemplate'))+" ")])]},proxy:true}])})]},proxy:true}])},[_c('ValidationObserver',{attrs:{"slim":""}},_vm._l((_vm.files),function(file,index){return _c('SlValidate',{key:file.id,attrs:{"vid":("" + (file.id)),"rules":{
            'required': true,
            'sl_url': {
              message: file.error,
              protocols: ['http', 'file']
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
          var validate = ref.validate;
          var reset = ref.reset;
return [_c('SlUploadLink',{attrs:{"value":file.url,"is-invalid":invalid,"validator":validate,"upload-callback":_vm.testSpreadsheetConnect,"uploaded":file.uploaded},on:{"upload-success":function (value) { return _vm.handleFileUrlUploaded(Object.assign({}, value, {index: index})); },"upload-error":function (error) { return _vm.handleFileUrlUploadError({ error: error, index: index}); },"input":function (value) { return _vm.handleUpdateFileUrl({ value: value, index: index }); },"remove":function (cb) { return _vm.handleRemoveFileUrl({ index: index, reset: reset, cb: cb }); }}})]}}],null,true)})}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }