<template>
  <SubPageWrapper empty-cols>
    <ContentBlock>
      <IntegrationContent>
        <template #title>
          {{ title }}
        </template>
        <div
          v-if="isDataPulled && !isExport"
          class="integration-content-row"
        >
          <SlAlert
            :accent="$t('Web.Integrations.Alerts.PullSuccess')"
            type="success"
            round
          >
            <template #icon>
              <icon
                data="@icons/check_circle.svg"
                class="fill-off size-20"
              />
            </template>
          </SlAlert>
        </div>
        <ValidationObserver ref="observer">
          <div
            v-if="isCloudConnection"
            class="integration-content-row w-480"
          >
            <SlValidate
              v-slot="{ invalid }"
              vid="environment"
              mode="eager"
              rules="required"
            >
              <SlSelect
                v-model="environmentModel"
                :select-label="$t('Web.BaseConnectors.Form.Environment')"
                :options="environmentOptions"
                track-by="id"
                label="value"
                :loading="isLoading"
                :is-invalid="invalid"
                :placeholder="$t('Web.Select.SelectOrTypeToSearch')"
                searchable
                required
                @open="handleEnvironmentsSelectOpen"
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-480">
            <SlValidate
              v-slot="{ invalid }"
              v-tooltip.bottom="companiesTooltip"
              vid="company"
              mode="eager"
              rules="required"
            >
              <SlSelect
                v-model="companyModel"
                :select-label="$t('Web.BaseConnectors.Form.Company')"
                :options="companyOptions"
                track-by="id"
                label="name"
                :loading="isLoading"
                :disabled="isCloudConnection && !environmentModel"
                :is-invalid="invalid"
                :placeholder="$t('Web.Select.SelectOrTypeToSearch')"
                return-object
                searchable
                required
                @open="handleCompaniesSelectOpen"
              >
                <template
                  v-if="needNoOptionLabel"
                  #noOptions
                >
                  {{ noCompanyOptionsLabel }}
                </template>
              </SlSelect>
            </SlValidate>
          </div>
          <div
            v-if="!isExport"
            class="integration-content-row w-480 mb-0"
          >
            <SlValidate
              v-slot="{ invalid }"
              vid="startDate"
              rules="sl_date_between|sl_valid_date"
            >
              <SlDatePicker
                v-model="startDate"
                :label="$t('DbInsideImport.Ui.lbStartDate')"
                :is-invalid="invalid"
                class="w-160"
                inline
              />
            </SlValidate>
          </div>
        </ValidationObserver>
        <template #actions>
          <template v-if="!isExport">
            <SlButton
              v-if="!isDataPulled"
              @click="handlePullData"
            >
              {{ $t('Web.Integrations.Actions.BtnPullData') }}
            </SlButton>
            <SlButton
              v-else
              variant="secondary"
              color="grey"
              @click="handleRePull"
            >
              {{ $t('Web.Integrations.Actions.BtnRePull') }}
            </SlButton>
          </template>
        </template>
      </IntegrationContent>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import logger from '@/api/logger';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { integrationModuleTypes, stepStatuses } from '@/config/integrations';
import { bc365ConnectionTypes } from '@/config/connection/BC365.config';
import { operationInfoTypes } from '@/config/api/operations.config';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';
import { toArray } from '@/helpers/utils/toArray';
import { openLink } from '@/helpers/shared/webAPI';
import { getTooltip } from '@/helpers/shared/tooltip';
import { dealWith } from '@/api/helpers/errorRegistry';

export default {
  name: 'PullData',
  components: {
    IntegrationContent
  },
  mixins: [modal],
  inject: ['resetStepsToCurrent'],
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      connection: state => state.integrations.settings.connection,
      configuration: state => state.integrations.settings.configuration,
      environments: state => state.integrations.bc365.environments || [],
      companies: state => state.integrations.bc365.companies || [],
      activeIntegration: state => state.integrations.active_integration
    }),
    ...mapGetters({
      activeStep: 'integrations/activeStep',
      isAllStepsCompleted: 'integrations/isAllStepsCompleted'
    }),
    isExport() {
      return this.activeIntegration.module === integrationModuleTypes.EXPORT;
    },
    environmentOptions() {
      if (this.environments.length) {
        return this.environments;
      }

      if (this.configuration.environmentId) {
        return [{
          id: this.configuration.environmentId,
          value: this.configuration.environmentDisplayName
        }];
      }

      return [];
    },
    companyOptions() {
      if (this.companies.length) {
        return this.companies;
      }

      if (this.configuration.companyId) {
        return [{
          id: this.configuration.companyId,
          name: this.configuration.company
        }];
      }

      return [];
    },
    isDataPulled() {
      return this.activeStep.status === stepStatuses.COMPLETED;
    },
    isCloudConnection() {
      return this.connection.solution === bc365ConnectionTypes.CLOUD;
    },
    title() {
      return this.isExport
        ? this.$t('Web.Integrations.Steps.setup')
        : this.$t('Web.Integrations.Steps.pull-data');
    },
    startDate: {
      get() {
        return this.configuration.startDate;
      },
      set(value) {
        this.updateConfiguration({
          startDate: value
        });
      }
    },
    needNoOptionLabel() {
      return !this.isLoading && !this.companyOptions.length;
    },
    noCompanyOptionsLabel() {
      if (!this.isCloudConnection) {
        return this.$t('Web.DbImport.TitleNoData');
      }

      if (!this.environmentModel) {
        return this.$t('Web.BaseConnectors.Form.NoEnvironment');
      }

      if (!this.companyOptions.length) {
        return this.$t('Web.BaseConnectors.Form.NoCompanies');
      }

      return this.$t('Web.DbImport.TitleNoData');
    },
    environmentModel: {
      get() {
        return this.configuration.environmentId;
      },
      async set(value) {
        this.companyModel = null;
        this.setCompanies(null);
        this.isCompaniesLoaded = false;

        await this.updateConfiguration({
          environmentId : value ?? ''
        });
      }
    },
    companyModel: {
      get() {
        const { companyId: id, company: name } = this.configuration;

        return id ? {
          id,
          name
        } : '';
      },
      set(value) {
        this.updateConfiguration({
          company: value?.name ?? '',
          companyId: value?.id ?? ''
        });
      }
    },
    companiesTooltip() {
      return getTooltip({
        disabled: !(this.isCloudConnection && !this.environmentModel),
        content: this.$t('Web.BC365.Form.EnvironmentTooltip')
      });
    }
  },
  methods: {
    ...mapActions({
      updateConfiguration: 'integrations/settings/updateConfiguration',
      setActiveStepStatus: 'integrations/setActiveStepStatus',
      updateIntegrationSettings: 'integrations/updateIntegrationSettings',
      importIntegration: 'integrations/importIntegration',
      fetchEnvironments: 'integrations/bc365/fetchEnvironments',
      setEnvironments: 'integrations/bc365/setEnvironments',
      fetchCompanies: 'integrations/bc365/fetchCompanies',
      setCompanies: 'integrations/bc365/setCompanies',
      resetTransformState: 'integrations/transform/resetState',
      subscribe: 'operations/subscribe',
      logout: 'user/logout'
    }),
    getConfigurationSettings() {
      return {
        ...(!this.isCloudConnection ? {
          baseUrl: this.connection.baseUrl,
          login: this.connection.login,
          password: this.connection.password
        } : {
          environmentId: this.configuration.environmentId ?? ''
        }),
        company: this.configuration.company ?? '',
        companyId: this.configuration.companyId ?? '',
        solution: this.connection.solution
      };
    },
    handleRePull() {
      this.resetStepsToCurrent(() => {
        this.resetTransformState();
        this.handlePullData();
      });
    },
    async handlePullData() {
      try {
        const valid = await this.$refs.observer.validate();

        if (!valid) {
          return;
        }

        this.showModal(modalsId.SL_LOADING_MODAL, {
          title: this.$t('Web.Integrations.Modals.TitlePullingData'),
          text: this.$t('Web.Integrations.Modals.TextPullingData')
        });

        await this.updateIntegrationSettings(this.getConfigurationSettings());

        await this.subscribe({
          subscriber: this.importIntegration
        });

        this.setActiveStepStatus(stepStatuses.COMPLETED);
      } catch (e) {
        this.showModal(modalsId.SL_ERROR_MODAL, {
          title: this.$t('Web.Integrations.Modals.TitlePullingDataError'),
          text: e?.message || this.$t('Web.Integrations.Modals.TextPullingDataError')
        });

        logger.formatAndWriteError({
          e,
          from: 'bc365/handlePullData'
        });
      } finally {
        setTimeout(() => {
          this.hideModal(modalsId.SL_LOADING_MODAL);
        }, 150);
      }
    },
    handleEnvironmentsSelectOpen() {
      if (!this.isEnvironmentsLoaded) {
        this.updateEnvironments();
      }
    },
    handleCompaniesSelectOpen() {
      if (this.companies.length || this.isConnectionError) {
        return;
      }

      if (!this.isCompaniesLoaded) {
        this.updateCompanies();
      }
    },
    handleBgOperationError(e) {
      this.isConnectionError = true;

      dealWith({
        [slErrorCodes.OAUTH_TIMEOUT_ERROR]: {
          silent: true,
          before: () => {
            this.showModal(modalsId.BC365_CONNECTION_TIMEOUT, {
              source: this.$t('Web.BC365.Title')
            });
          }
        },
        '*': {
          text: (e) => e.message
        }
      })(e);
    },
    async updateCompanies() {
      try {
        this.isLoading = true;

        const { operationData } = await this.subscribe({
          subscriber: () => this.fetchCompanies(this.activeIntegration.id),
          paused: ({ operationData, type }) => {
            if (operationData.url && type === operationInfoTypes.NEED_OAUTH) {
              openLink(operationData.url, true);
            }
          }
        });

        this.isCompaniesLoaded = true;
        this.isConnectionError = false;

        this.setCompanies(toArray(operationData.companies));
      } catch (e) {
        this.handleBgOperationError(e);

        this.logout({
          e,
          from: 'bc365/updateCompanies'
        });
      } finally {
        this.isLoading = false;
      }
    },
    async updateEnvironments() {
      try {
        this.isLoading = true;

        const { operationData } = await this.subscribe({
          subscriber: async() => this.fetchEnvironments(this.activeIntegration.id),
          paused: ({ operationData, type }) => {
            if (operationData.url && type === operationInfoTypes.NEED_OAUTH) {
              openLink(operationData.url, true);
            }
          }
        });

        this.isEnvironmentsLoaded = true;
        this.isConnectionError = false;

        const environments = toArray(operationData.values);

        this.setEnvironments(environments);
      } catch (e) {
        this.handleBgOperationError(e);

        this.logout({
          e,
          from: 'bc365/updateEnvironments'
        });
      } finally {
        this.isLoading = false;
      }
    },
    // used for export
    async finish() {
      try {
        const valid = await this.$refs.observer.validate();

        if (!valid) {
          return;
        }

        await this.updateIntegrationSettings(this.getConfigurationSettings());

        this.setActiveStepStatus(stepStatuses.COMPLETED);

        return this.isAllStepsCompleted;
      } catch {
        return false;
      }
    }
  }
};
</script>
