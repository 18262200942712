export const bitnessOptions = [
  {
    label: '32',
    value: 'x32'
  },
  {
    label: '64',
    value: 'x64'
  }
];

export const sqlVersionOptions = [
  {
    label: 'Microsoft SQL Server 2014',
    value: 'Microsoft SQL Server 2014'
  },
  {
    label: 'Microsoft SQL Server 2016',
    value: 'Microsoft SQL Server 2016'
  },
  {
    label: 'Microsoft SQL Server 2017',
    value: 'Microsoft SQL Server 2017'
  },
  {
    label: 'Microsoft SQL Server 2019',
    value: 'Microsoft SQL Server 2019'
  }
];