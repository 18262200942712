<template>
  <IntegrationContent>
    <template #title>
      {{ tabTitle }}
    </template>
    <template
      v-if="previewRows.length"
      #subtitle
    >
      {{ $t('Web.Spreadsheet.TabSubtitle') }}
    </template>
    <SlTable
      v-if="previewHeaders"
      :id="`${tabKey}-preview`"
      :headers="previewHeaders"
      :lower-headers="previewSlots"
      :rows="previewRows"
      header-unique-key="key"
      header-name-key="text"
      unique-key="id"
      :max-height="previewTableMaxHeight"
      :loading="tabData.isLoading"
      inner-no-data
      inner-no-data-editable
    >
      <template
        v-for="(header, headerIndex) in previewHeaders"
        #[`lower-header-${header.key}`]
      >
        <SlTableSelect
          v-if="header.mappingSupported"
          :key="header.key"
          :value="colSlotValue(headerIndex)"
          :options="colSlotOptions(headerIndex)"
          label="name"
          track-by="colMeaning"
          allow-empty
          return-object
          searchable
          clearable
          @input="(value) => handleUpdateColSlot(value, headerIndex)"
        />
      </template>
      <template
        v-if="previewLimitVisible"
        #before-outer
      >
        <div class="rows-limit-message body-1 grey-60">
          {{ $t('Web.DbImport.TablePreviewLimit', { 1: previewRowsLimit }) }}
        </div>
      </template>
      <template #no-data>
        <SlNoData>
          <template #text>
            {{ $t('Web.Spreadsheet.NoDataText', { 1: tabTitle }) }}
          </template>
        </SlNoData>
      </template>
    </SlTable>
  </IntegrationContent>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent';
import { getColumnSlotOptions } from '@/helpers/connection';
import { preventTabClose } from '@/helpers/shared/webAPI';
import { toArray } from '@/helpers/utils/toArray';

export default {
  name: 'Import',
  components: {
    IntegrationContent
  },
  props: {
    tabKey: {
      type: String,
      required: true
    },
    tabTitle: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      previewTableMaxHeight: 1100,
      previewRowsLimit: 100
    };
  },
  computed: {
    ...mapState({
      spreadsheetState: state => state.integrations.spreadsheet
    }),
    ...mapGetters({
      isCsv: 'integrations/spreadsheet/isCsv'
    }),
    tabData() {
      return this.spreadsheetState[this.tabKey];
    },
    preview() {
      return this.tabData.importPreview || {};
    },
    previewHeaders() {
      return toArray(this.preview?.headers);
    },
    previewRows() {
      return toArray(this.preview?.rows);
    },
    previewSlots() {
      // pass same headers again to sync col keys
      return this.tabData.availableSlots ? this.previewHeaders : null;
    },
    previewLimitVisible() {
      return this.previewRows?.length >= this.previewRowsLimit;
    }
  },
  async created() {
    if ((this.tabData.isLoaded || this.tabData.isLoading) && !this.isCsv) {
      return;
    }

    this.fetchPreview();
  },
  methods: {
    ...mapActions({
      fetchTablePreview: 'integrations/spreadsheet/fetchTablePreview',
      setTablePreviewResult: 'integrations/spreadsheet/setTablePreviewResult',
      setColSlot: 'integrations/spreadsheet/setColSlot',
      updateTabSettings: 'integrations/spreadsheet/updateTabSettings',
      subscribe: 'operations/subscribe'
    }),
    async fetchPreview() {
      try {
        this.updateTabSettings({
          tab: this.tabKey,
          key: 'isLoading',
          value: true
        });

        const { operationData } = await this.subscribe({
          subscriber: () => this.fetchTablePreview(this.tabKey)
        });

        this.setTablePreviewResult({
          data: operationData,
          tab: this.tabKey
        });
      } catch (e) {
        const errorMessage = e?.message;

        if (errorMessage) {
          this.$notify({
            type: 'error',
            text: errorMessage,
            duration: 15000
          });
        }
      } finally {
        this.updateTabSettings({
          tab: this.tabKey,
          key: 'isLoaded',
          value: true
        });

        this.updateTabSettings({
          tab: this.tabKey,
          key: 'isLoading',
          value: false
        });
      }
    },
    colSlotOptions(colIndex) {
      return getColumnSlotOptions(this.tabData, colIndex);
    },
    colSlotValue(index) {
      return this.tabData.matchedSlots[index];
    },
    handleUpdateColSlot(slotValue, colIndex) {
      this.setColSlot({
        tab: this.tabKey,
        value: slotValue,
        index: colIndex
      });

      preventTabClose(true);
    }
  }
};
</script>
