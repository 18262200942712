<template>
  <SubPageWrapper empty-cols>
    <ContentBlock>
      <IntegrationContent>
        <template #title>
          {{ $t('Web.Integrations.ConnectionTitle', { 1: $t('Web.CustomApi.Title')}) }}
        </template>
        <ValidationObserver
          ref="observer"
          slim
        >
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="username"
              mode="eager"
              rules="required"
            >
              <SlInput
                v-model="username"
                :label="$t('Web.CustomApi.Input.Username')"
                :is-invalid="invalid"
                :disabled="isPulling"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row w-50">
            <SlValidate
              v-slot="{ invalid }"
              vid="password"
              mode="eager"
              rules="required"
            >
              <SlPasswordInput
                v-model="password"
                :label="$t('Web.CustomApi.Input.Password')"
                :is-invalid="invalid"
                :disabled="isPulling"
                required
              />
            </SlValidate>
          </div>
          <div class="integration-content-row integration-content-row--column">
            <div class="heading-4-sb grey-100">
              {{ $t('Web.CustomApi.PullDataExportEndpointsTitle') }}
            </div>
            <div class="body-1 integration-content__subtitle">
              {{ $t('Web.CustomApi.PullDataEndpointsSubTitle') }}
            </div>
          </div>
          <div
            v-for="(endpoint, key) in exportEndpointsList"
            :key="key"
            class="integration-content-row w-50"
          >
            <SlValidate
              v-slot="{ invalid }"
              :vid="`${key}Validator`"
              mode="eager"
              rules="sl_url"
            >
              <SlInput
                v-model="endpoints[key]"
                :label="endpoint.label"
                :disabled="isPulling"
                :is-invalid="invalid"
              />
            </SlValidate>
          </div>
          <div class="integration-content-row">
            <SlCheckbox
              v-model="allowUntrustedCert"
              :label="$t('Web.BaseConnectors.Form.allowUntrustedCert')"
            >
              <SlInfoButton v-tooltip="allowSelfSignedCertTooltip" />
            </SlCheckbox>
          </div>
        </ValidationObserver>
      </IntegrationContent>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import { modal } from '@/mixins/modal';
import { getSplitTooltip } from '@/helpers/shared/tooltip';
import { exportEndpointsList } from '@/config/integrations/customapi.config';
import { stepStatuses } from '@/config/integrations';

export default {
  name: 'PullData',
  components: {
    IntegrationContent
  },
  mixins: [modal],
  data() {
    return {
      isPulling: false,
      username: '',
      password: '',
      allowUntrustedCert: false,
      endpoints: {
        exportPO: '',
        exportTO: '',
        exportMO: ''
      },
      exportEndpointsList
    };
  },
  computed: {
    ...mapState({
      configuration: state => state.integrations.settings.configuration
    }),
    ...mapGetters({
      isAllStepsCompleted: 'integrations/isAllStepsCompleted'
    }),
    allowSelfSignedCertTooltip() {
      return getSplitTooltip({
        title: this.$t('Web.BaseConnectors.Form.allowUntrustedCertTooltipTitle'),
        body: `<ul style="list-style:disc; white-space: normal">
          <li style="margin-left: 20px">${this.$t('Web.BaseConnectors.Form.allowUntrustedCertTooltipBody1')}</li>
          <li style="margin-left: 20px">${this.$t('Web.BaseConnectors.Form.allowUntrustedCertTooltipBody2')}</li>
        </ul>`
      });
    },
    isEndpointsEmpty() {
      return !Object.values(this.endpoints).some(value => value);
    }
  },
  beforeMount() {
    Object.keys(this.configuration).forEach(key => {
      if (this[key] !== undefined) {
        this[key] = this.configuration[key];
      }
    });
  },
  methods: {
    ...mapActions({
      updateIntegrationSettings: 'integrations/updateIntegrationSettings',
      setActiveStepStatus: 'integrations/setActiveStepStatus'
    }),
    getConnectionSettings() {
      return {
        username: this.username,
        password: this.password,
        pageLimit: this.pageLimit,
        allowUntrustedCert: this.allowUntrustedCert,
        endpoints: this.endpoints
      };
    },
    async finish() {
      try {
        const valid = await this.$refs.observer.validate();

        if (!valid) {
          return;
        }

        if (this.isEndpointsEmpty) {
          this.$notify({
            type: 'error',
            title: this.$t('Web.Error.EndpointsErrorTitle'),
            text: this.$t('Web.Error.EndpointsError')
          });

          return;
        }

        await this.updateIntegrationSettings(this.getConnectionSettings());

        this.setActiveStepStatus(stepStatuses.COMPLETED);

        return this.isAllStepsCompleted;
      } catch {
        return false;
      }
    }
  }
};
</script>