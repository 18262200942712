var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SubPageWrapper',{attrs:{"empty-cols":""}},[_c('ContentBlock',[_c('IntegrationContent',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title-wrapper"},[_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.ConnectionTitle', { 1: _vm.$t('Web.DbImport.Title')}))+" "),_c('SlLink',{attrs:{"href":_vm.GUIDE_DATABASE_CONNECTION_URL,"target":"_blank"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-16",attrs:{"data":require("@icons/question.svg")}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.LinkHelp'))+" ")])],1)]},proxy:true},{key:"actions",fn:function(){return [_c('SlButton',{attrs:{"loading":_vm.isConnecting},on:{"click":_vm.handleConnect}},[_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.ButtonConnect'))+" ")])]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""}},[_c('div',{staticClass:"integration-content-row w-50"},[_c('SlControl',{attrs:{"options":_vm.connectionTypeOptions,"label":_vm.$t('Web.DbImport.LabelConnectionType'),"disabled":_vm.isConnecting},model:{value:(_vm.driverType),callback:function ($$v) {_vm.driverType=$$v},expression:"driverType"}})],1),(_vm.isSQLConnection)?[_c('div',{staticClass:"integration-content-row integration-content-row--split w-50"},[_c('SlValidate',{key:"host",attrs:{"vid":"host","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbInsideSourceWidget.Ui.lbHost'),"disabled":_vm.isConnecting,"is-invalid":invalid,"required":""},model:{value:(_vm.server),callback:function ($$v) {_vm.server=$$v},expression:"server"}})]}}],null,false,929772486)}),_c('SlValidate',{key:"port",staticClass:"integration-content-row--port",attrs:{"vid":"port","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbInsideSourceWidget.Ui.lbPort'),"disabled":_vm.isConnecting,"is-invalid":invalid,"type":"number","required":""},model:{value:(_vm.port),callback:function ($$v) {_vm.port=$$v},expression:"port"}})]}}],null,false,3630909806)})],1),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{key:"databaseName",attrs:{"vid":"databaseName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbInsideSourceWidget.Ui.lbDatabase'),"disabled":_vm.isConnecting,"is-invalid":invalid,"required":""},model:{value:(_vm.database),callback:function ($$v) {_vm.database=$$v},expression:"database"}})]}}],null,false,3033385251)})],1)]:[_c('div',{staticClass:"integration-content-row"},[_c('SlValidate',{key:"datasourceName",attrs:{"vid":"datasourceName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.DbImport.ConnectionString'),"placeholder":_vm.datasourceNamePlaceholder,"disabled":_vm.isConnecting,"is-invalid":invalid,"required":""},model:{value:(_vm.datasourceName),callback:function ($$v) {_vm.datasourceName=$$v},expression:"datasourceName"}})]}}])})],1)],_c('div',{staticClass:"integration-content-row w-50"},[_c('SlInput',{attrs:{"label":_vm.$t('DbImportDialog.Ui.lbUserName'),"disabled":_vm.isConnecting},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('div',{staticClass:"integration-content-row w-50 mb-0"},[_c('SlPasswordInput',{attrs:{"label":_vm.$t('DbImportDialog.Ui.lbPassword'),"is-set":_vm.password.isSet,"disabled":_vm.isConnecting},model:{value:(_vm.password.value),callback:function ($$v) {_vm.$set(_vm.password, "value", $$v)},expression:"password.value"}})],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }