<template>
  <SubPageWrapper empty-cols>
    <ContentBlock>
      <IntegrationContent>
        <template #title>
          {{ $t('Web.Integrations.ConnectionTitle', { 1: $t('Web.Transactional.Title')}) }}
        </template>
        <template #subtitle>
          <i18n
            v-if="isFreePlan"
            path="Web.Transactional.GetStarted"
            tag="p"
          >
            <template v-slot:1>
              <SlLink
                :href="FREE_VERSION_TUTORIAL_URL"
                target="_blank"
              >
                {{ $t('Web.Transactional.WatchVideo') }}
              </SlLink>
            </template>
          </i18n>
          <i18n path="Web.Transactional.DownloadTemplate">
            <template v-slot:1>
              <SlLink
                :href="SPREADSHEET_TRANSACTIONAL_TEMPLATE_URL"
                target="_blank"
              >
                {{ $t('Web.Transactional.DataTemplate') }}
              </SlLink>
            </template>
          </i18n>
        </template>
        <ValidationObserver slim>
          <SlValidate
            v-for="(file, index) in files"
            :key="file.id"
            v-slot="{ invalid, validate, reset }"
            :vid="`${file.id}`"
            :rules="{
              'required': true,
              'sl_url': {
                message: file.error,
                protocols: ['http', 'file']
              }
            }"
          >
            <SlUploadLink
              :value="file.url"
              :is-invalid="invalid"
              :validator="validate"
              :upload-callback="testSpreadsheetConnect"
              :uploaded="file.uploaded"
              @upload-success="(value) => handleFileUrlUploaded({ ...value, index})"
              @upload-error="(error) => handleFileUrlUploadError({ error, index})"
              @input="(value) => handleUpdateFileUrl({ value, index })"
              @remove="(cb) => handleRemoveFileUrl({ index, reset, cb })"
            />
          </SlValidate>
        </ValidationObserver>
      </IntegrationContent>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import {
  FREE_VERSION_TUTORIAL_URL,
  SPREADSHEET_TRANSACTIONAL_TEMPLATE_URL
} from '@/config/shared/resources.config';
import { stepStatuses } from '@/config/integrations';
import { uuId } from '@/helpers/shared/uuId';
import cloneObject from '@/helpers/utils/cloneObject';

export default {
  name: 'FileUpload',
  components: {
    IntegrationContent
  },
  data() {
    return {
      FREE_VERSION_TUTORIAL_URL,
      SPREADSHEET_TRANSACTIONAL_TEMPLATE_URL,
      files: []
    };
  },
  inject: ['resetStepsToCurrent'],
  computed: {
    ...mapState({
      activeSteps: (state) => state.integrations.active_steps,
      activeIntegration: (state) => state.integrations.active_integration,
      storeFiles: state => state.integrations.settings.connection.files
    }),
    ...mapGetters({
      activeStepIndex: 'integrations/activeStepIndex',
      isFreePlan: 'account/isFreePlan'
    })
  },
  created() {
    if (this.storeFiles?.length) {
      this.files = cloneObject(this.storeFiles).map(file => ({
        ...file,
        uploaded: !!file.name
      }));
    } else {
      this.files = [this.getFileUrlItem()];
    }
  },
  methods: {
    ...mapActions({
      setActiveStepStatus: 'integrations/setActiveStepStatus',
      fetchConnectionSettings: 'integrations/fetchConnectionSettings',
      updateConnectionSettings: 'integrations/updateConnectionSettings',
      testDatasourceConnection: 'integrations/testDatasourceConnection',
      fetchBasicSettings: 'integrations/spreadsheet/fetchBasicSettings',
      matchDefaultSheet: 'integrations/spreadsheet/matchDefaultSheet',
      resetState: 'integrations/spreadsheet/resetState'
    }),
    getFileUrlItem() {
      return {
        id: uuId(),
        name: '',
        url: '',
        uploaded: false
      };
    },
    checkSameUrl(value) {
      return this.files.some(file => file.url === value);
    },
    async testSpreadsheetConnect({ value }) {
      await this.updateConnectionSettings({
        url: value
      });

      return this.testDatasourceConnection();
    },
    async handleFileUrlUploaded({ index }) {
      this.$set(this.files, index, {
        ...this.files[index],
        uploaded: true
      });

      await this.fetchConnectionSettings(this.activeIntegration);
      await this.fetchBasicSettings();
      this.setActiveStepStatus(stepStatuses.COMPLETED);
      this.matchDefaultSheet();
    },
    handleFileUrlUploadError({ index, error }) {
      this.$set(this.files, index, {
        ...this.files[index],
        error: error?.message
      });
    },
    handleUpdateFileUrl({ value, index }) {
      this.$set(this.files, index, {
        ...this.files[index],
        url: value,
        error: this.checkSameUrl(value)
          ? this.$t('Web.FileUpload.UrlAlreadyExist')
          : ''
      });
    },
    handleRemoveFileUrl({ index, reset, cb }) {
      const removeCallback = () => {
        const newItems = this.files.length > 1 ? [] : [this.getFileUrlItem()];

        this.files.splice(index, 1, ...newItems);

        this.resetState();
        reset && reset();
        cb && cb();
      };

      if (this.activeSteps[this.activeStepIndex + 1].status !== stepStatuses.INCOMPLETE) {
        return this.resetStepsToCurrent(removeCallback);
      }

      this.setActiveStepStatus(stepStatuses.INCOMPLETE);
      removeCallback();
    }
  }
};
</script>
