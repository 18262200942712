<template>
  <div class="connector-content">
    <div class="connector-content__header">
      <div class="connector-content__title heading-3-m grey-70">
        <template v-if="$sl_isEditingRoute">
          {{ title }}
        </template>
        <SlHiddenInput
          v-else
          :value="title"
          :is-valid="isValidTitle"
          @save="handleProjectNameChange"
        />
      </div>
      <div class="connector-content__actions">
        <slot name="actions" />
      </div>
    </div>
    <div
      v-if="$scopedSlots.alerts"
      class="connector-content__header-alerts"
    >
      <slot name="alerts" />
    </div>
    <div
      class="connector-content__main"
      :class="{
        'connector-content__main--footer': $scopedSlots['main-footer']
      }"
    >
      <slot />
    </div>
    <div
      v-if="$scopedSlots['main-footer']"
      class="connector-content__footer"
    >
      <slot name="main-footer" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ConnectorContentWrapper',
  props: {
    title: {
      type: String,
      default: ''
    },
    isValidTitle: Boolean
  },
  computed: {
    ...mapState({
      connectorType: state => state.connection.selectedConnector
    })
  },
  methods: {
    ...mapActions({
      setConnectorValueByKey: 'connection/setConnectorValueByKey'
    }),
    handleProjectNameChange(value) {
      this.setConnectorValueByKey({
        type: this.connectorType,
        field: 'commonData',
        key: 'slProjectName',
        value
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/connections/connectors/common/connector-content-wrapper.scss";
</style>
