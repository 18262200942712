var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SubPageWrapper',{attrs:{"empty-cols":""}},[_c('ContentBlock',[_c('IntegrationContent',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title-wrapper"},[_vm._v(" "+_vm._s(_vm.$t('Main.Ui.acImportFromSapB1'))+" "),_c('SlLink',{attrs:{"href":_vm.GUIDE_SAP_EXPORT_URL,"target":"_blank"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-16",attrs:{"data":require("@icons/question.svg")}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.LinkHelp'))+" ")])],1)]},proxy:true}])},[_c('div',{staticClass:"integration-content-row"},[_c('SlAlertLine',{attrs:{"type":"warn","column":""}},[_c('div',{staticClass:"sl-alert__accent"},[_c('i18n',{attrs:{"path":"Web.SapB1.AlertTitle"},scopedSlots:_vm._u([{key:"1",fn:function(){return [_c('SlLink',{attrs:{"href":_vm.SAP_PROXY_DOWNLOAD_URL,"target":"_blank","variant":"yellow"}},[_vm._v(" "+_vm._s(_vm.$t('Web.SapB1.AlertLink'))+" ")])]},proxy:true}])})],1),_c('div',{staticClass:"sl-alert__text"},[_vm._v(" "+_vm._s(_vm.$t('Web.SapB1.AlertText'))+" ")])])],1),_c('ValidationObserver',{ref:"observer",attrs:{"slim":""}},[_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"database","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbInsideSourceWidget.Ui.lbDatabase'),"disabled":_vm.isConnecting,"is-invalid":invalid,"required":""},model:{value:(_vm.database),callback:function ($$v) {_vm.database=$$v},expression:"database"}})]}}])})],1),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"serverName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.DbRelatedConnectors.Form.LabelSapServerName'),"disabled":_vm.isConnecting,"is-invalid":invalid,"required":""},model:{value:(_vm.serverName),callback:function ($$v) {_vm.serverName=$$v},expression:"serverName"}})]}}])})],1),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.DbRelatedConnectors.Form.LabelSapUsername'),"disabled":_vm.isConnecting,"is-invalid":invalid,"required":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}])})],1),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"password","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlPasswordInput',{attrs:{"label":_vm.$t('Web.DbRelatedConnectors.Form.LabelSapPassword'),"disabled":_vm.isConnecting,"is-set":_vm.password.isSet,"is-invalid":invalid,"required":""},model:{value:(_vm.password.value),callback:function ($$v) {_vm.$set(_vm.password, "value", $$v)},expression:"password.value"}})]}}])})],1),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"sldAddress","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbInsideImport.Names.SapSLDAddress'),"disabled":_vm.isConnecting,"is-invalid":invalid,"required":""},model:{value:(_vm.sldAddress),callback:function ($$v) {_vm.sldAddress=$$v},expression:"sldAddress"}})]}}])})],1),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"clientBitness","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlSelect',{attrs:{"options":_vm.bitnessOptions,"select-label":_vm.$t('DbInsideSourceWidget.Ui.lbBitness'),"disabled":_vm.isConnecting,"is-invalid":invalid,"required":""},model:{value:(_vm.clientBitness),callback:function ($$v) {_vm.clientBitness=$$v},expression:"clientBitness"}})]}}])})],1),_c('div',{staticClass:"integration-content-row w-50"},[_c('SlValidate',{attrs:{"vid":"sqlServerVersion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlSelect',{attrs:{"options":_vm.sqlVersionOptions,"select-label":_vm.$t('DbInsideImport.Ui.lbSqlVersion'),"disabled":_vm.isConnecting,"is-invalid":invalid,"required":""},model:{value:(_vm.sqlServerVersion),callback:function ($$v) {_vm.sqlServerVersion=$$v},expression:"sqlServerVersion"}})]}}])})],1),_c('div',{staticClass:"integration-content-row"},[_c('SlCheckbox',{attrs:{"label":_vm.$t('DbInsideImport.Ui.chPOinsteadTO'),"disabled":_vm.isConnecting},model:{value:(_vm.poInsteadTO),callback:function ($$v) {_vm.poInsteadTO=$$v},expression:"poInsteadTO"}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }