<template>
  <SubPageWrapper empty-cols>
    <OAuthLoginModal :id="modalsId.BC365_OAUTH_LOGIN_2" />
    <ConnectionTimeoutModal :id="modalsId.BC365_CONNECTION_TIMEOUT_2" />

    <ContentBlock>
      <IntegrationContent>
        <template #title>
          <div class="title-wrapper">
            {{ $t('Web.Integrations.ConnectionTitle', { 1: $t('Web.BC365.Title')}) }}
            <SlLink
              :href="GUIDE_BC365_CONNECTION_URL"
              target="_blank"
            >
              <template #prepend>
                <icon
                  data="@icons/question.svg"
                  class="fill-off size-16"
                />
              </template>
              {{ $t('Web.DbImport.LinkHelp') }}
            </SlLink>
          </div>
        </template>

        <ValidationObserver
          ref="observer"
          slim
        >
          <div
            v-if="!isIntegrationEditing"
            class="integration-content-row w-50"
          >
            <SlControl
              v-model="solution"
              :options="connectionTypeOptions"
              :label="$t('Web.BC365.LabelConnectionType')"
            />
          </div>
          <template v-if="isOnPremisesConnection">
            <div class="integration-content-row w-50">
              <SlValidate
                v-slot="{ invalid }"
                vid="baseUrl"
                mode="eager"
                rules="required|sl_url"
              >
                <SlInput
                  v-model="baseUrl"
                  :label="$t('Web.BC365.Form.URL')"
                  :is-invalid="invalid"
                  required
                />
              </SlValidate>
            </div>
            <div class="integration-content-row w-50">
              <SlValidate
                v-slot="{ invalid }"
                vid="username"
                mode="eager"
                rules="required"
              >
                <SlInput
                  v-model="login"
                  :label="$t('Web.BC365.Form.Username')"
                  :is-invalid="invalid"
                  required
                />
              </SlValidate>
            </div>
            <div class="integration-content-row w-50 mb-0">
              <SlValidate
                v-slot="{ invalid }"
                key="password"
                vid="password"
                rules="required"
              >
                <SlPasswordInput
                  v-model="password"
                  :label="$t('Web.BC365.Form.Password')"
                  :is-invalid="invalid"
                  required
                />
              </SlValidate>
            </div>
          </template>
          <template v-else>
            <span class="body-1 grey-70">
              {{ $t('Web.BC365.ContinueToConnect') }}
            </span>
          </template>
        </ValidationObserver>

        <template #actions>
          <SlButton
            :loading="isConnecting"
            @click="handleConnect"
          >
            {{ $t('Web.DbImport.ButtonConnect') }}
          </SlButton>
        </template>
      </IntegrationContent>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import OAuthLoginModal from '@/components/Modals/Shared/OAuthLoginModal';
import ConnectionTimeoutModal from '@/components/Modals/Shared/ConnectionTimeoutModal';
import { modal } from '@/mixins/modal';
import { stepStatuses } from '@/config/integrations';
import { connectionTypeOptions, bc365ConnectionTypes } from '@/config/connection/BC365.config';
import { operationInfoTypes } from '@/config/api/operations.config';
import modalsId from '@/config/shared/modalsId.config';
import { GUIDE_BC365_CONNECTION_URL } from '@/config/shared/resources.config';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';
import { openLink } from '@/helpers/shared/webAPI';
import { toArray } from '@/helpers/utils/toArray';
import { addCodeToMessage, dealWith } from '@/api/helpers/errorRegistry';

export default {
  name: 'Connect',
  components: {
    IntegrationContent,
    ConnectionTimeoutModal,
    OAuthLoginModal
  },
  mixins: [modal],
  data() {
    return {
      GUIDE_BC365_CONNECTION_URL,
      modalsId,
      solution: bc365ConnectionTypes.ON_PREMISES,
      baseUrl: '',
      login: '',
      password: '',
      oAuthLink: null,
      operationId: null,
      isConnecting: false
    };
  },
  inject: [
    'resetStepsToCurrent'
  ],
  computed: {
    ...mapState({
      connection: state => state.integrations.settings.connection,
      activeIntegration: state => state.integrations.active_integration
    }),
    ...mapGetters({
      activeStep: 'integrations/activeStep',
      isIntegrationEditing: 'integrations/isIntegrationEditing'
    }),
    connectionTypeOptions() {
      return connectionTypeOptions(this);
    },
    isOnPremisesConnection() {
      return this.solution === bc365ConnectionTypes.ON_PREMISES;
    }
  },
  created() {
    Object.keys(this.connection).forEach(key => {
      if (this[key] !== undefined) {
        this[key] = this.connection[key];
      }
    });
  },
  methods: {
    ...mapActions({
      setActiveStepStatus: 'integrations/setActiveStepStatus',
      connectToDatasource: 'integrations/connectToDatasource',
      updateConnectionSettings: 'integrations/updateConnectionSettings',
      resetTransformState: 'integrations/transform/resetState',
      fetchEnvironments: 'integrations/bc365/fetchEnvironments',
      setEnvironments: 'integrations/bc365/setEnvironments',
      fetchCompanies: 'integrations/bc365/fetchCompanies',
      setCompanies: 'integrations/bc365/setCompanies',
      subscribe: 'operations/subscribe',
      unsubscribe: 'operations/unsubscribe',
      logout: 'user/logout'
    }),
    handleLoginCancel() {
      if (this.operationId) {
        this.unsubscribe(this.operationId);
      }

      this.oAuthLink = null;
      this.isConnecting = false;
      this.operationId = null;
    },
    handleLoginTryAgain() {
      if (this.oAuthLink) {
        return openLink(this.oAuthLink, true);
      }

      this.testConnection();
    },
    getConnectionSettings() {
      return {
        ...(this.isOnPremisesConnection
          ? {
            baseUrl: this.baseUrl,
            login: this.login,
            password: this.password
          }
          : {}),
        solution: this.solution
      };
    },
    async handleConnect() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        return;
      }

      if (this.activeStep.status === stepStatuses.COMPLETED) {
        return this.resetStepsToCurrent(() => {
          this.resetTransformState();
          this.testConnection();
        });
      }

      this.testConnection();
    },
    async readEnvironments() {
      try {
        await this.subscribe({
          subscriber: async() => {
            const response = await this.connectToDatasource();

            this.operationId = response?.data?.operationId;

            return response;
          },
          paused: ({ operationData, type }) => {
            if (operationData.url && type === operationInfoTypes.NEED_OAUTH) {
              this.oAuthLink = operationData.url;

              openLink(operationData.url, true);

              this.showModal(modalsId.BC365_OAUTH_LOGIN_2, {
                source: this.$t('Web.BC365.Title'),
                tryAgainCallback: this.handleLoginTryAgain,
                cancelCallback: this.handleLoginCancel
              });
            }
          }
        });

        const { operationData } = await this.subscribe({
          subscriber: () => this.fetchEnvironments(this.activeIntegration.id)
        });

        this.setCompanies(null);
        this.setEnvironments(toArray(operationData.values));

        return true;
      } catch (e) {
        dealWith({
          [slErrorCodes.OAUTH_TIMEOUT_ERROR]: {
            silent: true,
            before: () => {
              this.showModal(modalsId.BC365_CONNECTION_TIMEOUT_2, {
                source: this.$t('Web.BC365.Title')
              });
            }
          },
          '*': {
            text: (e) => e.message
          }
        })(e);

        this.logout({
          e,
          from: 'bc365/readEnvironments'
        });
      } finally {
        this.oAuthLink = null;
        this.operationId = null;

        this.hideModal(modalsId.BC365_OAUTH_LOGIN_2);
      }
    },
    async readCompanies() {
      try {
        const { operationData } = await this.subscribe({
          subscriber: () => this.fetchCompanies(this.activeIntegration.id),
          paused: ({ operationData, type }) => {
            if (operationData.url && type === operationInfoTypes.NEED_OAUTH) {
              openLink(operationData.url, true);
            }
          }
        });

        this.setCompanies(toArray(operationData.companies));

        return true;
      } catch (e) {
        this.showModal(modalsId.CONNECTOR_ERROR, {
          error: e?.message
        });

        this.logout({
          e,
          from: 'integrations/bc365/fetchCompanies'
        });
      } finally {
        this.hideModal(modalsId.BC365_OAUTH_LOGIN_2);
      }
    },
    async testConnection() {
      try {
        this.isConnecting = true;

        await this.updateConnectionSettings(this.getConnectionSettings());

        let connected = false;

        if (this.isOnPremisesConnection) {
          connected = await this.readCompanies();
        } else {
          connected = await this.readEnvironments();
        }

        if (!connected) {
          return;
        }

        this.setActiveStepStatus(stepStatuses.COMPLETED);

        this.$notify({
          type: 'success',
          text: this.$t('Web.Integrations.ConnectSuccess', { 1: this.$t('Web.BC365.Title')})
        });
      } catch (e) {
        const defaultErrorText = this.$t('Web.Integrations.ConnectError', { 1: this.$t('Web.BC365.Title')});

        this.$notify({
          type: 'error',
          text: e?.message || addCodeToMessage(defaultErrorText, e?.code)
        });
      } finally {
        this.isConnecting = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.integration-content {
  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
